import React, { Fragment, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Style_Panel_Global from "../../../assets/css/Panel_styles/panel.module.css";


import {
    Icon_panel_Menu_Close, Icon_dollar_mini
} from "../../../assets/icones/icones";
import {togglePopup_show_hide} from "../../../clientconfigwebapp";
import Box from "@mui/material/Box";
import logo_webapp from "../../../assets/images/logo.png";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import pay_pal_icon from "../../../assets/images/pay-pal.png";



const Popup_Wallet = (props) => {


    const [amount, setAmount] = useState('20');

    const handleButtonClick = (value) => {
        setAmount(value);
    };


    return (
        <Fragment>


                <div id="wallet" className="hidden fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center transition-opacity duration-300 opacity-0">
                    <div className="w-full lg:w-[40%] px-[3%] lg:px-[2%]   bg-white p-5 rounded shadow">

                        <span className={"text-[rgb(155,_155,_155)] relative left-0 top-0 text-[23px] cursor-pointer"}
                              onClick={() => togglePopup_show_hide('wallet')}>X</span>

                        <div className={"logo-modal-webapp"}>
                            <img src={logo_webapp}/>
                            <span>Recharge wallet</span>
                        </div>

                        <div className={"lg:px-[14%]"}>


                            <span  className={"block mb-[3px]"}>Amount</span>
                            <TextField
                                placeholder={"Amount"}
                                id="amount"
                                name="amount"
                                variant="filled"
                                className={"inputboxloginreg"}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <Icon_dollar_mini width={20} height={20}/>
                                    </InputAdornment>,
                                }}
                                value={amount}

                            />


                            <div className={"flex justify-between items-center flex-row mt-[15px]"}>
                                {[20, 30, 50, 100].map((value) => (
                                    <button
                                        key={value}
                                        className="border-[solid] border-[2px] border-[#c1c1c1] pr-[14px] pl-[14px] pt-[4px] pb-[4px] rounded flex flex-row justify-around items-center content-center hover:border-[#8AD6D1]"
                                        onClick={() => handleButtonClick(value)}
                                    >
                                        <Icon_dollar_mini width={20} height={20}/>
                                        <span> {value}</span>
                                    </button>
                                ))}
                            </div>


                            <div className={"gateway-img"}>
                                <span className={"block mt-[18px] mb-[5px]"}>Payment Method</span>
                                <img src={pay_pal_icon}/>
                            </div>


                            <button style={{
                                "marginTop": "20px",
                                "paddingTop": "12px",
                                "paddingBottom": "12px"
                            }} type={"button"}
                                    className="justify-center items-center px-16   text-lg font-medium leading-8 text-white rounded-lg max-md:px-5 max-md:mt-10 btn-green-dim-bh w-100">
                                pay
                            </button>


                        </div>


                    </div>
                </div>


        </Fragment>
    );
};

export default Popup_Wallet;
