import React, {Fragment, useState} from "react";
import Menu_Sidebar from "./Menu_Sidebar";
import Style_Panel_Global from "../../assets/css/Panel_styles/panel.module.css";
import {ToastContainer} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';



import Logo_webapp from "../../assets/images/logo.png"


import {Icon_panel_History_mobile, Icon_panel_Menu_mobile} from "../../assets/icones/icones";


import Popup_Wallet from "./Popups_Views/Popup_Wallet";
import Popup_Profile_Edit from "./Popups_Views/Popup_Profile_Edit";


const Master_Panel = (props) => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    return (
        <Fragment>
            <style jsx global>{`
                body {

                    background-color: #2b3034;
                    width: 100%;
                }

                .App {
                    background-color: #2b3034;
                }
            `}</style>

            <ToastContainer/>
            <div id={"headerbk"} className={"px-[3.5%] py-[5px] mb-[5px] lg:mb-[30px]"}>

                <Icon_panel_Menu_mobile   className="lg:hidden"  width={20} height={20} onClick={handleDrawerToggle} />

                <img src={Logo_webapp} alt={"logo"} className={"w-[130px] lg:w-[250px]  py-[10px] lg:pt-[12px] "}/>
                <button
                    type="button"
                    className="toggle_button_menu_history lg:hidden"
                    onClick={props.toggleSidebar} // افزودن onClick handler
                    aria-label="Toggle Sidebar"
                >
                    <Icon_panel_History_mobile width={20} height={20}   />
                </button>


            </div>


            <div className={`px-[2%] ${Style_Panel_Global.box_Conent_Full_Panel}`}>
                <div className={"flex flex-col lg:flex-row "}>

                    <Menu_Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle}
                                  wallet_balance={props.wallet_balance}
                                  first_name_last_name={props.first_name_last_name}
                                      first_name_profile={props.first_name_profile}
                                      last_name_profile={props.last_name_profile}
                                      profile_src={props.profile_src}
                        />

                    <main
                        className={
                            'box_front_master_panel w-100   pt-[80px] lg:pt-[10px] lg:ml-[1.5%] lg:mr-[1.5%] ' +
                            (props.custom_class_main ? String(props.custom_class_main) : ' ')
                        }>
                        {props.type_content === 'title_show' && (
                            <div
                                className={"flex justify-start items-center gap-x-[7px] pt-[16px] border-b border-solid border-b-[1px] border-b-[#F0F0F0] pb-[14px]"}>
                                {props.icon_svg}{props.title_page}
                            </div>
                        )}
                        {props.children}


                    </main>
                </div>
            </div>


            <div className="flex flex-col items-center">

                <Popup_Wallet />

                <Popup_Profile_Edit />

            </div>


        </Fragment>
    );
}

export default Master_Panel;
