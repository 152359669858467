import axios from "axios";
import React, {useEffect, useState, useRef, Fragment} from "react";


import {Link, NavLink, useParams, useNavigate} from "react-router-dom"
import {render} from "react-dom";
import clientconfigwebapp, {
    empty_bk,
    convert_to_english_strhk,
    scrollTo_uphk,
    modal_login_reg_bk_show,
    modal_login_reg_all_hide,
    redirectbh_typeb,
} from '../../clientconfigwebapp';
import {ToastContainer, toast} from 'react-toastify';


import TextField from '@mui/material/TextField';

import InputAdornment from '@mui/material/InputAdornment';
import {
    Icon_reg_user,
    Icon_reg_email,
    Icon_reg_password,
    Icon_reg_verification_code, Icon_Email, Icon_Lock_Pass, Icon_User_reg
} from "../../assets/icones/icones";



import ReactFullPageLoadinghk from "../../components/layaout/LoadingFullpagehk";
import Logo_site from "../../assets/images/logo.png";


const serialize = require('form-serialize');


const Modal_Register = (props) => {
    const {type_user_login_reg} = useParams();

    const navigate = useNavigate();
    const [loadinghkfullshow, Setloadinghkfullshow] = useState("false");


    const [reg_verification_code, setRegVerification_code] = useState('');
    const [page_view_reg_verification_page, set_page_view_reg_verification_page] = useState('reg_verification_page');

    const [email_address_login_reg_check, set_email_address_login_reg_check] = useState('');


    /* timer start*/
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });

    useEffect(() => {
        scrollTo_uphk();
        setMinutes(2);


    }, []);

    /* timer end*/


    const Handler_reg_get_code = () => {


        let form_data = document.querySelector('#form_Registerpagewebapp');
        if (form_data != null) {
            let data_serialize = serialize(form_data);


            Setloadinghkfullshow("true");
            axios.post(`${clientconfigwebapp.siteurl}api/v1/req-register-sendcode-pr/`, data_serialize)
                .then((res) => {
                    Setloadinghkfullshow("false");

                    if (!empty_bk(res.data.data)) {


                        if (String(res.data.data) == "reg_ver_page") {
                            modal_login_reg_bk_show("verification_code_reg_show");
                        }


                    } else {
                        toast.error(res.data.msgwebapp, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
/// alert('errcode:An error occurred code 421156');
                    }


                }).catch((err) => {
                Setloadinghkfullshow("false");
                toast.error('Error connecting to the server, code 5431', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                console.error(err, "is error 5431!");

            });


        } else {

            alert('errcode:657567567567');
        }


    }


    const Handler_reg_complate = () => {


        let form_data = document.querySelector('#form_Registerpagewebapp');
        if (form_data != null) {
            let data_serialize = serialize(form_data);


            Setloadinghkfullshow("true");
            axios.post(`${clientconfigwebapp.siteurl}api/v1/req-register-verification-pr/`, data_serialize)
                .then((res) => {
                    Setloadinghkfullshow("false");

                    if (!empty_bk(res.data.data)) {


                        localStorage.setItem('tokenacess', res.data.data);
                        if (!empty_bk(res.data.first_name)) {
                            localStorage.setItem('first_name_Get', res.data.first_name);
                        }
                        if (!empty_bk(res.data.last_name)) {
                            localStorage.setItem('last_name_Get', res.data.last_name);
                        }

                        modal_login_reg_all_hide();

                        redirectbh_typeb(String(clientconfigwebapp.webappurl))


                    } else {
                        toast.error(res.data.msgwebapp, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
/// alert('errcode:An error occurred code 421156');
                    }


                }).catch((err) => {
                Setloadinghkfullshow("false");
                toast.error('Error connecting to the server, code 5431', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                console.error(err, "is error 5431!");

            });


        } else {

            alert('errcode:657567567567');
        }


    }


    const verification_code_reg_Handler = (event) => {
        setRegVerification_code(convert_to_english_strhk(event.target.value));


        if (String(convert_to_english_strhk(event.target.value)).length == 5) {
///Handler_Register_login_Verification();
        }

    }


    const HandleLogin_show_bk = () => {
        modal_login_reg_bk_show("login_reg_check_view_modal", "");
    };

    return (<Fragment>
            <ReactFullPageLoadinghk loadinghk={loadinghkfullshow}/>

            <div id="register_view_modal" className="flexColStartStart hidden">
                <div className="flex items-center justify-center flex-col h-screen">
                    <img className="w-1/3 mb-8" src={Logo_site} alt="Logo"/>

                    <span className="text-[19px] font-['Poppins'] mb-[11px] font-semibold">Welcome</span>

                    <section
                        className="flex flex-col w-[45%] max-md:ml-0 max-md:w-full border-solid border border-[#ededed] p-[16px] pt-[61px] pb-[61px] rounded-[10px] pr-[4%] pl-[4%]">
                        <div className="flex flex-col max-md:mt-10">
                            <TextField
                                placeholder="First name"
                                id="first_name_reg"
                                name="first_name_reg"
                                variant="filled"
                                className="inputboxloginreg"
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">
                                        <Icon_User_reg className="inputboxloginreg-icon w-8 h-8" />
                                        </InputAdornment>),
                                }}
                            />

                            <TextField
                                placeholder="Last name"
                                id="last_name_reg"
                                name="last_name_reg"
                                variant="filled"
                                className="inputboxloginreg !mt-[22px]"
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">
                                        <Icon_User_reg className="inputboxloginreg-icon w-8 h-8" />
                                        </InputAdornment>),
                                }}
                            />

                            <TextField
                                placeholder="Email"
                                id="email_address_reg"
                                name="email_address_reg"
                                variant="filled"
                                className="inputboxloginreg !mt-[22px]"
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">
                                        <Icon_Email className="inputboxloginreg-icon w-8 h-8" />
                                        </InputAdornment>),
                                }}
                            />

                            <TextField
                                placeholder="Password"
                                id="password_reg"
                                name="password_reg"
                                variant="filled"
                                className="inputboxloginreg !mt-[22px]"
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">
                                        <Icon_Lock_Pass className="inputboxloginreg-icon w-8 h-8" />
                                        </InputAdornment>),
                                }}
                            />

                            <button
                                type="button"
                                onClick={Handler_reg_get_code}
                                className="justify-center items-center px-16 py-3 mt-5 text-lg font-medium leading-8 text-white rounded-lg max-md:px-5 max-md:mt-10 btn-green-dim-bh"
                            >
                                Register
                            </button>

                            <p className="self-center mt-5 text-sm leading-5 text-center text-lime-500">
                                <span className="text-zinc-500">I have an account?</span>{" "}
                                <button
                                    onClick={HandleLogin_show_bk}
                                    type="button"
                                    className="text-blue-600 font-bold"
                                >
                                    Login now!
                                </button>
                            </p>
                        </div>
                    </section>
                </div>
            </div>

            <div
                id="verification_code_reg_view_modal"
                className="fixed w-[45%] bg-white top-[28vh] z-[111] left-0 right-0 shadow-md p-4 rounded-lg ml-[27%] block"
            >
                <div className="modal-reg-verification-wrapper">
            <span className="block text-center pt-2 pb-5 text-black font-bold">
            Enter the verification code.
            </span>

                    <div className="box-input-formreg-login flex flex-col justify-center items-center w-full">
                        <TextField
                            label="Verification Code"
                            placeholder="Verification Code"
                            id="verification_code_reg"
                            name="verification_code_reg"
                            variant="outlined"
                            className="!mt-[22px]"
                            onChange={verification_code_reg_Handler}
                            InputProps={{
                                startAdornment: (<InputAdornment position="start">
                                        <Icon_Lock_Pass className="inputboxloginreg-icon w-8 h-8"/>
                                    </InputAdornment>),
                            }}
                        />
                    </div>

                    <div className="text-center pt-2 pb-4">
                        {minutes === 0 && seconds === 0 ? (<button
                                onClick={Handler_reg_get_code}
                                type="button"
                                className="border-none bg-white"
                            >
                            <span className="text-[12px] text-[#cbb5b5] font-['Poppins'] pl-2">
                            Didn't receive the code?
                            </span>
                                <span className="text-[15px] text-[#ff5e14] font-['Poppins'] pl-2">
                            Resend
                            </span>
                            </button>) : (<div>
                            <span className="text-[12px] text-[#cbb5b5] font-['Poppins'] pl-2">
                            Didn't receive the code?
                            </span>
                                <span className="text-[15px] text-[#ff5e14] font-['Poppins'] pl-2">
                            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                            </span>
                            </div>)}
                    </div>

                    <div className="flex flex-row justify-center items-center">
                        <button
                            onClick={Handler_reg_complate}
                            type="button"
                            className="btn-bh btn-black-bh w-[37%]"
                        >
                            Confirm Code
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>


    );
}


export default Modal_Register;