import axios from "axios";
import React, {useEffect, useState, useRef, Fragment} from "react";
import {Link, NavLink, useParams, useNavigate, useLocation} from "react-router-dom"
import {render} from "react-dom";
import clientconfigwebapp, {
    empty_bk, convert_to_english_strhk, scrollTo_uphk, check_login_appbk, Token_get_appbk, redirectbh_typeb,
} from '../../clientconfigwebapp';
import {ToastContainer, toast} from 'react-toastify';

import Master_Front from "../../pages/Master_Front";


import ReactFullPageLoadinghk from "../../components/layaout/LoadingFullpagehk";


import '../../components/assets/css/profile.css'


import Button from '@mui/material/Button';

import serialize from "form-serialize";
import {Back} from "../../assets/icones/icones";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";


const Change_password = (props) => {
    const location = useLocation()
    const {tab_show_by_url} = useParams();

    const location_get_urlbk = useLocation();
    const redirect_navigate = useNavigate();
    const [loadinghkfullshow, Setloadinghkfullshow] = useState("false");

    /*  start profile */
    const [wallet_balance, setData_wallet_balance] = useState('0');
    const [first_name_profile,setData_first_name_profile] = useState('');
    const [last_name_profile,setData_last_name_profile] = useState('');
    const [profile_src,setData_profile_src] = useState('');

    /*  end profile */


    const [showPassword, setShowPassword] = React.useState(false);


    const [old_password,set_old_Password] = useState('');
    const [password,setPassword] = useState('');
    const [password_confirm,setpassword_confirm] = useState('');

    useEffect(() => {
        scrollTo_uphk();
        if(check_login_appbk()==false){
            redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login" );
            return false;
        }




        Load_api_page();

    }, [location_get_urlbk]);



    const Load_api_page= () => {

        if(check_login_appbk()==false){  redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login" ); }

        const config = {
            headers: { Authorization: `Bearer ${Token_get_appbk()}` }
        };


        Setloadinghkfullshow("true");

///    form_data.append("page","my_calender_times");
        axios.post(`${clientconfigwebapp.siteurl}api/v1/dashboard-get`,null,config)
            .then((res) => {
                Setloadinghkfullshow("false");
                console.log(res.data);
                if(String(res.data).length > 0) {

                    if(String(res.data.data)=="login-show"){  redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login" );  } ////dddddd


                    if(String(res.data.wallet_inventory).length > 0) {  setData_wallet_balance(res.data.wallet_inventory); }

                    if (!empty_bk(res.data.first_name_profile)) { setData_first_name_profile(res.data.first_name_profile);    localStorage.setItem('first_name_Get',res.data.first_name_profile);    }
                    if (!empty_bk(res.data.last_name_profile)) { setData_last_name_profile(res.data.last_name_profile); localStorage.setItem('last_name_Get',res.data.last_name_profile);    }

                    if (!empty_bk(res.data.profile_src)) {
                        setData_profile_src(res.data.profile_src);
                    }





                }else{
                    if(String(res.data.msgwebapp).length > 0)  toast.error(res.data.msgwebapp, {position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined,});
                }

            }).catch((err) =>{
            Setloadinghkfullshow("false");
            toast.error('Error connecting to the server, code 435346', {position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined,});
        });





    }



    /*  start new  */


    const Handler_old_password=(event)=>{
        set_old_Password(convert_to_english_strhk(event.target.value));
    }

    const Handler_password=(event)=>{
        setPassword(convert_to_english_strhk(event.target.value));
    }


    const Handler_password_confirm=(event)=>{
        setpassword_confirm(convert_to_english_strhk(event.target.value));
    }

    const Handler_Change_password= () => {

        if(check_login_appbk()==false){  redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login" ); }

        const config = {
            headers: { Authorization: `Bearer ${Token_get_appbk()}` }
        };



        if (old_password === '') { toast.error('Old Password not entered', {position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined,});return false; }
        if (password === '') { toast.error('new Password  not entered', {position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined,});return false; }
        if (password_confirm === '') { toast.error('confirm Password not entered', {position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined,});return false; }


        if (String(password) !=String(password_confirm)) { toast.error('The new password and the Confirm password do not match', {position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined,});return false; }


        Setloadinghkfullshow("true");


        let form_data = document.querySelector('#form_data_profile');
        if (form_data != null) {

            let data_serialize = serialize(form_data);


            axios.post(`${clientconfigwebapp.siteurl}api/v1/panel/change-password/`,data_serialize,config)
                .then((res) => {

                    Setloadinghkfullshow("false");

                    if(String(res.data).length > 0) {

                        if(String(res.data.data)=="login-show"){  redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login" );  }
                        if(String(res.data.data)=="no-access"){  redirectbh_typeb(String(clientconfigwebapp.webappurl) + "no-access" );  }


                        if(String(res.data.status)=="success"){
                            toast.success(res.data.msgwebapp, {position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined,});

                            redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login" );

                        }else{

                            if(String(res.data.msgwebapp).length > 0)  toast.error(res.data.msgwebapp, {position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined,});

                        }





                    }else{
                        if(String(res.data.msgwebapp).length > 0)  toast.error(res.data.msgwebapp, {position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined,});
                    }

                }).catch((err) =>{
                Setloadinghkfullshow("false");
                toast.error('Error connecting to the server, code 6786324', {position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined,});
            });





        }






    }



    return (<Fragment>

        <Master_Front   wallet_balance={wallet_balance} first_name_profile={first_name_profile} last_name_profile={last_name_profile} profile_src={profile_src} >
            <ReactFullPageLoadinghk loadinghk={loadinghkfullshow}/>
            <ToastContainer/>


            <form id={"form_data_profile"} name={"form_data_profile"} encType="multipart/form-data"
                  style={{width: "100%"}}>


                <section className='flexColStartStart information_container'>

                    <Link to='/panel/dashboard' className='box-back-link'>
                        <Back/>
                        <span></span>
                        Password Change
                    </Link>

                    <section className='flexColStartStart information_wrapper'>


                        <Box sx={{display: 'flex', position: 'relative', marginTop: "25px"}}>
                            <TextField value={old_password} onChange={Handler_old_password} name={"old_password"}
                                       id="old_password" label="Old Password" type={showPassword ? 'text' : 'password'}
                                       fullWidth
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start">
                                               <svg width={20} height={20} viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                   <path
                                                       d="M5 8.33333V6.66667C5 3.90833 5.83333 2.5 10 2.5C14.1667 2.5 15 3.90833 15 6.66667V8.33333"
                                                       stroke="#838C91" strokeWidth="1.5" strokeLinecap="round"
                                                       strokeLinejoin="round"/>
                                                   <path
                                                       d="M9.99935 15.4167C11.1499 15.4167 12.0827 14.4839 12.0827 13.3333C12.0827 12.1827 11.1499 11.25 9.99935 11.25C8.84876 11.25 7.91602 12.1827 7.91602 13.3333C7.91602 14.4839 8.84876 15.4167 9.99935 15.4167Z"
                                                       stroke="#838C91" strokeWidth="1.5" strokeLinecap="round"
                                                       strokeLinejoin="round"/>
                                                   <path
                                                       d="M14.166 18.3333H5.83268C2.49935 18.3333 1.66602 17.4999 1.66602 14.1666V12.4999C1.66602 9.16659 2.49935 8.33325 5.83268 8.33325H14.166C17.4993 8.33325 18.3327 9.16659 18.3327 12.4999V14.1666C18.3327 17.4999 17.4993 18.3333 14.166 18.3333Z"
                                                       stroke="#838C91" strokeWidth="1.5" strokeLinecap="round"
                                                       strokeLinejoin="round"/>
                                               </svg>
                                           </InputAdornment>,
                                       }}


                            />
                            <IconButton onClick={() => {
                                setShowPassword(!showPassword)
                            }} sx={{position: 'absolute', right: 0, top: "10px"}}>
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </Box>


                        <Box sx={{display: 'flex', position: 'relative', marginTop: "15px"}}>
                            <TextField value={password} onChange={Handler_password} name={"password_new"}
                                       id="password_new" label="New Password" type={showPassword ? 'text' : 'password'}
                                       fullWidth
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start">
                                               <svg width={20} height={20} viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                   <path
                                                       d="M5 8.33333V6.66667C5 3.90833 5.83333 2.5 10 2.5C14.1667 2.5 15 3.90833 15 6.66667V8.33333"
                                                       stroke="#838C91" strokeWidth="1.5" strokeLinecap="round"
                                                       strokeLinejoin="round"/>
                                                   <path
                                                       d="M9.99935 15.4167C11.1499 15.4167 12.0827 14.4839 12.0827 13.3333C12.0827 12.1827 11.1499 11.25 9.99935 11.25C8.84876 11.25 7.91602 12.1827 7.91602 13.3333C7.91602 14.4839 8.84876 15.4167 9.99935 15.4167Z"
                                                       stroke="#838C91" strokeWidth="1.5" strokeLinecap="round"
                                                       strokeLinejoin="round"/>
                                                   <path
                                                       d="M14.166 18.3333H5.83268C2.49935 18.3333 1.66602 17.4999 1.66602 14.1666V12.4999C1.66602 9.16659 2.49935 8.33325 5.83268 8.33325H14.166C17.4993 8.33325 18.3327 9.16659 18.3327 12.4999V14.1666C18.3327 17.4999 17.4993 18.3333 14.166 18.3333Z"
                                                       stroke="#838C91" strokeWidth="1.5" strokeLinecap="round"
                                                       strokeLinejoin="round"/>
                                               </svg>
                                           </InputAdornment>,
                                       }}
                            />
                            <IconButton onClick={() => {
                                setShowPassword(!showPassword)
                            }} sx={{position: 'absolute', right: 0, top: "10px"}}>
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </Box>


                        <Box sx={{display: 'flex', position: 'relative', marginTop: "15px"}}>
                            <TextField value={password_confirm} onChange={Handler_password_confirm}
                                       name={"password_confirm"} id="password_confirm" label="Confirm Password"
                                       type={showPassword ? 'text' : 'password'} fullWidth
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start">
                                               <svg width={20} height={20} viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                   <path
                                                       d="M5 8.33333V6.66667C5 3.90833 5.83333 2.5 10 2.5C14.1667 2.5 15 3.90833 15 6.66667V8.33333"
                                                       stroke="#838C91" strokeWidth="1.5" strokeLinecap="round"
                                                       strokeLinejoin="round"/>
                                                   <path
                                                       d="M9.99935 15.4167C11.1499 15.4167 12.0827 14.4839 12.0827 13.3333C12.0827 12.1827 11.1499 11.25 9.99935 11.25C8.84876 11.25 7.91602 12.1827 7.91602 13.3333C7.91602 14.4839 8.84876 15.4167 9.99935 15.4167Z"
                                                       stroke="#838C91" strokeWidth="1.5" strokeLinecap="round"
                                                       strokeLinejoin="round"/>
                                                   <path
                                                       d="M14.166 18.3333H5.83268C2.49935 18.3333 1.66602 17.4999 1.66602 14.1666V12.4999C1.66602 9.16659 2.49935 8.33325 5.83268 8.33325H14.166C17.4993 8.33325 18.3327 9.16659 18.3327 12.4999V14.1666C18.3327 17.4999 17.4993 18.3333 14.166 18.3333Z"
                                                       stroke="#838C91" strokeWidth="1.5" strokeLinecap="round"
                                                       strokeLinejoin="round"/>
                                               </svg>
                                           </InputAdornment>,
                                       }}
                            />
                            <IconButton onClick={() => {
                                setShowPassword(!showPassword)
                            }} sx={{position: 'absolute', right: 0, top: "10px"}}>
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </Box>


                        <Button style={{marginTop: "10px"}} type={"button"} onClick={Handler_Change_password}
                                variant="contained">Update</Button>

                    </section>
                </section>
            </form>

        </Master_Front>
    </Fragment>);
}


export default Change_password;