import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';


import './assets/css/App.css';
import './assets/css/theme.css'
import 'react-toastify/dist/ReactToastify.css';


import Index_Login_Register from './pages/Login_Register/Index_Login_Register';

/////// Start route User simpale
import Profile_Edit from './pages/panel/Profile_Edit';
import Files_Page from './pages/panel/Files_Page';

import Change_password from "./pages/panel/Change_password";
import Tickets_Messages_View from './pages/panel/Tickets/Tickets_Messages_View';

/////// End route User simpale

function App() {


    if (!localStorage.getItem('themes_status')) {
        localStorage.setItem("themes_status", "white");
    }


    return (
        <div className="App">

            <Router>


                <Routes>


                    <Route path="/login-register" element={<Index_Login_Register/>}/>
                    <Route path="/login" element={<Index_Login_Register/>}/>


                    <Route path="/" element={<Tickets_Messages_View/>}/>
                    <Route path="/chat/:id_get_user_chat" element={<Tickets_Messages_View/>}/>


                    <Route path="/panel/files/:tab_show_by_url" element={<Files_Page/>}/>


                    <Route path="/panel/profile" element={<Profile_Edit/>}/>

                    <Route path="/panel/change-password" element={<Change_password/>}/>


                    <Route path="*" element={"404 Not Found"}/>
                </Routes>
            </Router>
        </div>
    );
}

export default App;
