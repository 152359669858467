// reducers.js
const initialState = {
  cartItems: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [],
  totalPrice: localStorage.getItem('price') ? Number(localStorage.getItem('price')) : 0
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      const find = FindItemInCart(state.cartItems , action.payload)
      if(find) {
        const updatedCartItems = state.cartItems.map(item => {
          console.log(action.payload)
          if (item.id === action.payload.id) {

            return { ...item, quantity: parseInt(item.quantity) + 1 };
          }
          return item;
        });
        const updatedTotalPrice = parseInt(state.totalPrice) + parseInt(getItemPriceById(state.cartItems, action.payload.id));
        setToLocalStroge(updatedCartItems , updatedTotalPrice)
        return {
          ...state,
          cartItems: updatedCartItems,
          totalPrice: updatedTotalPrice
        };
      }else {
        const updatedCartItems = [...state.cartItems, action.payload];
        const updatedTotalPrice = parseInt(state.totalPrice) + parseInt(action.payload.price);
        setToLocalStroge(updatedCartItems , updatedTotalPrice)
        return {
          ...state,
          cartItems: updatedCartItems,
          totalPrice: updatedTotalPrice
        };
      }

    case 'DECREASE_QUANTITY':
      const decreasedCartItems = state.cartItems.map(item => {
        if (item.id === action.payload) {
          return { ...item, quantity: item.quantity - 1 };
        }
        return item;
      });
      const decreasedTotalPrice = parseInt(state.totalPrice) - parseInt(getItemPriceById(state.cartItems, action.payload));
      setToLocalStroge(decreasedCartItems , decreasedTotalPrice)
      return {
        ...state,
        cartItems: decreasedCartItems,
        totalPrice: decreasedTotalPrice
      };

    case 'INCREASE_QUANTITY':
      const increasedCartItems = state.cartItems.map(item => {
        if (item.id === action.payload) {
          return { ...item, quantity: parseInt(item.quantity) + 1 };
        }
        return item;
      });
      const increasedTotalPrice = parseInt(state.totalPrice) + parseInt( getItemPriceById(state.cartItems, action.payload));
      setToLocalStroge(increasedCartItems , increasedTotalPrice)
      return {
        ...state,
        cartItems: increasedCartItems,
        totalPrice: increasedTotalPrice
      };

    case 'REMOVE_PRODUCT':
      const filteredCartItems = state.cartItems.filter(item => item.id !== action.payload);
      const removedProductPrice = getItemPriceById(state.cartItems, action.payload);
      const updatedTotalPriceAfterRemove = parseInt(state.totalPrice) - parseInt(removedProductPrice);
      setToLocalStroge(filteredCartItems , updatedTotalPriceAfterRemove)
      return {
        ...state,
        cartItems: filteredCartItems,
        totalPrice: updatedTotalPriceAfterRemove
      };

    default:
      return state;
  }
};

// Helper function to get the price of an item by its ID
const getItemPriceById = (cartItems, productId) => {
  const item = cartItems.find(item => item.id === productId);
  return item ? item.price : 0;
};
const FindItemInCart = (cartItems , productId) => {
  const find = cartItems.find(item => item.id == productId.id);
  return find ? true : false
}
const setToLocalStroge = (cart , price) => {
  localStorage.setItem('cart' , JSON.stringify(cart))
  localStorage.setItem('price' , price)
}

export default cartReducer;
  