import axios from "axios";
import React, {useEffect, useState, useRef, Fragment} from "react";
import {Link, NavLink, useParams, useNavigate, useLocation} from "react-router-dom"
import {render} from "react-dom";
import clientconfigwebapp, {
    empty_bk,
    convert_to_english_strhk,
    scrollTo_uphk,
    check_login_appbk,
    Token_get_appbk,
    redirectbh_typeb,
    scrollTo_bottom_enpage, redirect_wait_bk,
} from '../../../clientconfigwebapp';
import {ToastContainer, toast} from 'react-toastify';

import Master_Panel from "../../panel/Master_Panel";


import Chat_Messages_List from "../../../components/Ticket/Chat_Messages_List";


import "../../../components/assets/css/profile.css"
import "../../../assets/cssnew/panel.css"
import "../../../assets/cssnew/TicketPage-messages.css"

import ReactFullPageLoadinghk from "../../../components/layaout/LoadingFullpagehk";

import serialize from "form-serialize";

import {
    Back,
    Icon_Chat_List_users, Icon_close_history_menu,
    Icon_Profile_hk_Chat, Icon_Send_btn_bot,
    Icon_Trash_Chat,
    Logo_Icon_chat_ai
} from '../../../assets/icones/icones';
import Chat_List_Right from "../../../components/Ticket/Chat_List_Right";

import image_ask from "../../../assets/images/ask-anim.gif"


const Tickets_Messages_View = () => {


    /*  start profile */
    const [wallet_balance, setData_wallet_balance] = useState('0');
    const [first_name_profile, setData_first_name_profile] = useState('');
    const [last_name_profile, setData_last_name_profile] = useState('');
    const [profile_src, setData_profile_src] = useState('');
    const [type_user_panel, setData_type_user_panel] = useState('');
    /*  end profile */


    //Chats START
    const [data_full, setData_full_data_list] = useState([]);
    const [data_list_empty, setData_data_list_empty] = useState('');
    const [data_list, setData_data_list] = useState([]);
    const [data_list_chats, setdata_list_chats] = useState([]);
    const messageBoxRef = useRef(null);


    /*  chat live */
    const [message, setMessage] = useState('');
    const [chat, setChat] = useState([]);
    const [history, setHistory] = useState([]);
    const [language, setLanguage] = useState('en');
    const [token, setToken] = useState(localStorage.getItem('tokenacess') || null);
    /*  chat live end */

    const {id_get_user_chat} = useParams();

    const location_get_urlbk = useLocation();
    const [loadinghkfullshow, Setloadinghkfullshow] = useState("false");
    const [Data_Full_Get, set_Data_Full_Get] = useState([]);

    const [id_number_chat, setChatId] = useState(id_get_user_chat);


    useEffect(() => {
        if (id_number_chat !== id_get_user_chat) {
            const newUrl = `/chat/${id_number_chat}`;
            window.history.pushState(null, '', newUrl);
        }
    }, [id_number_chat, id_get_user_chat]);

    const updateParams = (newChatId) => {
        setChatId(newChatId);
    };


    useEffect(() => {
        if (messageBoxRef.current) {
            messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
        }
    }, [data_list]);

    useEffect(() => {
        if (messageBoxRef.current) {
            messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
        }
    }, [chat]);


    useEffect(() => {
        scrollTo_uphk();
        if (check_login_appbk() == false) {
            redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login");
            return false;
        }


        Load_api_page();


    }, [location_get_urlbk]);


    const Load_api_page = () => {
        scrollTo_uphk();
        if (check_login_appbk() == false) {
            redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login");
        }

        const config = {
            headers: {Authorization: `Bearer ${Token_get_appbk()}`}
        };

        Setloadinghkfullshow("true");

        const params = new URLSearchParams();
        params.append('id_number_chat', String(id_number_chat));

        axios.post(`${clientconfigwebapp.siteurl}api/v1/panel/ticket-get-messages-pr`, params, config)
            .then((res) => {
                Setloadinghkfullshow("false");

                if (String(res.data.data).length > 0) {

                    if (String(res.data.data) == "login-show") {
                        redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login");
                    }


                    if (!empty_bk(res.data.type_user)) {
                        setData_type_user_panel(res.data.type_user);
                    }

                    set_Data_Full_Get(res.data);

                    if (String(res.data.wallet_inventory).length > 0) {
                        setData_wallet_balance(res.data.wallet_inventory);
                    }

                    if (!empty_bk(res.data.first_name_profile)) {
                        setData_first_name_profile(res.data.first_name_profile);
                        localStorage.setItem('first_name_Get', res.data.first_name_profile);
                    }
                    if (!empty_bk(res.data.last_name_profile)) {
                        setData_last_name_profile(res.data.last_name_profile);
                        localStorage.setItem('last_name_Get', res.data.last_name_profile);
                    }

                    if (!empty_bk(res.data.profile_src)) {
                        setData_profile_src(res.data.profile_src);
                    }

                    if (String(res.data.data).length > 0) {
                        setData_full_data_list(res.data.data);
                    }

                    if (String(res.data.data.data_list_chats).length > 0) {
                        setdata_list_chats(res.data.data.data_list_chats);
                    } else {
                        setdata_list_chats([]);
                    }

                    if (String(res.data.data.data_list).length > 0) {
                        setData_data_list(res.data.data.data_list);
                    } else {
                        setData_data_list([]);
                        setData_data_list_empty("notfound")
                    }

                } else {
                    if (String(res.data.msgwebapp).length > 0) toast.error(res.data.msgwebapp, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            }).catch((err) => {
            Setloadinghkfullshow("false");
            toast.error('خطا اتصال به سرور کد  435346', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }





    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const toggleSidebar = () => {
        setIsSidebarVisible((prev) => !prev);
    };

    // رفرنس برای sidebar
    const sidebarRef = useRef(null);

    // بستن sidebar با کلیک خارج (اختیاری)
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                sidebarRef.current &&
                !sidebarRef.current.contains(event.target) &&
                !event.target.closest(".toggle_button_menu_history")
            ) {
                setIsSidebarVisible(false);
            }
        };

        if (isSidebarVisible) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isSidebarVisible])


    const checkLoginStatus = async () => {
        if (!token) {
            setToken(null);
            return;
        }

        try {
            const config = {
                headers: {Authorization: `Bearer ${token}`}
            };
            const res = await axios.post(String(clientconfigwebapp.site_url_py) + 'api/v1/loginchecknew', null, config);


            if (res.data.message !== 'Authenticated') {
                setToken(null);
                /// localStorage.removeItem('tokenacess');
            }
        } catch (error) {
            console.error('Authentication check failed:', error);
            setToken(null);
            //// localStorage.removeItem('tokenacess');
        }
    };

    const fetchHistory = async () => {
        if (!token) return;


        const config = {
            headers: {Authorization: `Bearer ${Token_get_appbk()}`}
        };

        try {
            const response = await axios.post(String(clientconfigwebapp.site_url_py) + 'api/chat-history', {}, config
            );
            setHistory(response.data);
        } catch (error) {
            console.error("Error fetching chat history:", error);
        }
    };

    const sendMessage = async () => {
        if (message.trim() === '' || !token) return;


        const config = {
            headers: {Authorization: `Bearer ${Token_get_appbk()}`}
        };

        const params = new URLSearchParams();
        params.append('id_number_chat', String(id_number_chat));


        axios.post(`${String(clientconfigwebapp.site_url_py)}api/chat`, {
            message,
            language,
            id_number_chat: id_number_chat
        }, config)
            .then((response) => {
                const newChat = {message, response: '', feedbackGiven: false};
                setChat([...chat, newChat]);
                typeResponse(response.data.answer, chat.length);
                setMessage('');

                if (response.data.status_new == "False" || response.data.status_new == "false" || response.data.status_new == false) {
                    updateParams(response.data.id_number_chat);

                }

                ///fetchHistory();
            })
            .catch((error) => {
                console.error("Error sending message:", error);
                // Handle the error by showing a toast or any other method
                toast.error('Error sending message', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };

    const typeResponse = (response, index) => {
        if (!response || response.trim() === '') {
            return;
        }

        let i = 0;
        const typingSpeed = 50;

        const intervalId = setInterval(() => {
            if (i < response.length) {
                setChat((prevChat) => {
                    const updatedChat = [...prevChat];
                    updatedChat[index].response = response.substring(0, i + 1);
                    return updatedChat;
                });
                i++;
            } else {
                clearInterval(intervalId);
            }
        }, typingSpeed);
    };


    const giveFeedback = async (index, feedback) => {
        const chatEntry = chat[index];
        try {
            await axios.post(String(clientconfigwebapp.site_url_py) + 'api/feedback',
                {
                    user_message: chatEntry.message,
                    bot_response: chatEntry.response,
                    feedback: feedback,
                },
                {headers: {Authorization: token}}
            );
            const updatedChat = [...chat];
            updatedChat[index].feedbackGiven = true;
            setChat(updatedChat);
        } catch (error) {
            console.error("Error giving feedback:", error);
        }
    };


    return (<Fragment>
        <style jsx>{`
            .App {
                background-color: #2b3034;
            }

        `}</style>

        <Master_Panel wallet_balance={wallet_balance} first_name_profile={first_name_profile}
                      last_name_profile={last_name_profile} profile_src={profile_src} toggleSidebar={toggleSidebar}>
            <ReactFullPageLoadinghk loadinghk={loadinghkfullshow}/>

            <ToastContainer/>
            <select
                style={{display: "none"}}
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
            >
                <option value="en">English</option>
                <option value="fa">fa</option>
            </select>


            <form id={"form_data_page"} name={"form_data_page"}>


                <div className=" flex flex-col  lg:grid  lg:grid-cols-4 gap-4 pt-4">

                    {/* Left side content */}
                    <div className="lg:col-span-3 bg-[white] p-[14px] rounded-[10px]">
                        <div className="box-tickets-bk px-[2%]" id={"box-tickets-bk"} ref={messageBoxRef}>
                            <div className="box-open-tickets-bk">
                                <div className="">


                                    {
                                        !id_number_chat && (
                                            <Fragment>

                                                <div className={"flex flex-col content-center items-center"}>


                                                    <span
                                                        className={"text-center block text-[23px] text-[#898989] font-['Poppins'] leading-[1.6] mt-[20%]"}>To start chatting, enter your question.</span>

                                                    <img src={image_ask}/>

                                                </div>


                                            </Fragment>
                                        )

                                    }


                                    {data_list && data_list.length > 0 && (

                                        <Fragment>
                                            <Chat_Messages_List type_view={"history"} data_list={data_list}/>
                                        </Fragment>

                                    )}


                                    {chat && chat.length > 0 && (

                                        <Fragment>
                                            <Chat_Messages_List type_view={"live"} data_list={chat}/>
                                        </Fragment>

                                    )}


                                </div>


                                <div
                                    className="fixed w-[95%] left-[3%] lg:left-[12%] bottom-[3vh] lg:w-[64.4%] h-[52px] p-0 rounded-[11px] bg-[white]">



                                    <textarea

                                        className={"w-full pl-[15px] pr-[110px] py-[15px] bg-[#fff] resize-none border-[solid] border-[1px] border-[#ddd] [box-shadow:0_3px_8px_rgba(0,_0,_0,_0.05)] [direction:ltr] text-[13px] font-light [transition:ease-in-out_0.15s]"}
                                        name="text_message" value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        rows={1}
                                        placeholder="Ask any question..."/>


                                    <div className="absolute -right-[4px] top-[28px]">
                                        <div
                                            className={"flex flex-nowrap items-center justify-center content-center flex-row relative -top-[28px]"}>
                                            <button type="button"
                                                    className={"bg-[rgb(138,_214,_209)] p-[8px] rounded-[4px] cursor-pointer text-[20px] mx-[3px] my-0 border-[none] h-[52px]"}
                                                    onClick={sendMessage}>
                                                <Icon_Send_btn_bot width={36} height={36}/>
                                            </button>
                                        </div>
                                    </div>

                                    <div id="result_req_saved_pr"/>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div
                        ref={sidebarRef}
                        className={`sidebar-list-chat bg-white p-4 rounded-tr-[14px] rounded-br-[14px] transition-transform duration-300 ease-in-out transform ${
                            isSidebarVisible ? "translate-x-0" : "-translate-x-full"
                        } lg:translate-x-0 lg:static lg:block`}
                    >

                        <Icon_close_history_menu width={15} height={15}  onClick={toggleSidebar} className={"mb-[20px] mt-[14px] lg:hidden"}  />
                        <Chat_List_Right data_list={data_list_chats}/>
                    </div>


                </div>

            </form>
        </Master_Panel>

    </Fragment>);
}

export default Tickets_Messages_View;
