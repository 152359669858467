import React, {Fragment, useState} from "react";

import Box from '@mui/material/Box';

import Header from "../components/Header";
import '../assets/cssnew/aside.css'

import 'react-toastify/dist/ReactToastify.css';
import {useLocation} from "react-router-dom";

import Menue_Panel from "../components/Header/Panel_layouts/Menue_Panel";


const Master_Front = (props) => {
    const location = useLocation();


    const[show_menu_panel,setshow_menu_panel] = useState('hide');

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };




    return (
        <Fragment>

<style jsx>{`
body{
background:  #F2F2F3 !important;
}

`}</style>



            <Header  type_user={"simple"}  handleDrawerToggle={handleDrawerToggle}   wallet_balance={props.wallet_balance} first_name_profile={props.first_name_profile} last_name_profile={props.last_name_profile} profile_src={props.profile_src}  />


            <Box sx={{ display: 'flex' }}>

                {/*  This code must be before the BOX element.  */}
                <Menue_Panel type_user={"simple"}  mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle}    menu_show={show_menu_panel} wallet_balance={props.wallet_balance} first_name_last_name={props.first_name_last_name}   />


                <Box className={"box_main_page_panel"} component="main" sx={{flexGrow: 1, bgcolor: 'background.default', p: 3}}>
                    <section className="panel_main">
                        {props.children}
                    </section>
                </Box>



            </Box>



        </Fragment>
    );
}

export default Master_Front;