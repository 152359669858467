import '../assets/cssnew/header.css'
import logo from '../assets/images/logo.png'
import { Menu } from '../assets/icones/icones';
import {Fragment, useState,useEffect} from 'react';
import clientconfigwebapp, {check_login_appbk, redirectbh_typeb, Token_get_appbk} from "../clientconfigwebapp";
import MenuIcon from '@mui/icons-material/Menu';


import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import axios from "axios";
import {toast} from "react-toastify";

import prof_img from '../assets/images/prof.jpg'

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? 'rgba(83,205,51,0.85)' : '#2bb008',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

////function Header(props) {
const Header = (props) => {

        const [status , setStatus] = useState(false);

    const [statusOnline, setStatusOnline] = useState('offline');

    useEffect(() => {
        if (props.status_online) {
            setStatusOnline(props.status_online);
        }
    }, [props.status_online]);

    const { handleDrawerToggle } =props;
  const menuOpenHandler = () => {
        const back = document.querySelector('.back_dark')
        const menu = document.querySelector('.panel_aside')
        back.style.display = 'block'
        menu.style.left = '0'
  }
  const userMenuOpen = () => {
    const menu = document.querySelector('.user_menu')
    if(status == false) {
          menu.style.display = 'block'
          setStatus(true)
    }else {
      menu.style.display = 'none'
      setStatus(false)
    }

  }

    const handleUpdateStatusOnline = (event) => {
        const newStatus = event.target.checked ? 'online' : 'offline';
        setStatusOnline(newStatus);
        Handler_Update_Status_Online();

    };

    const Handler_Update_Status_Online = () => {

        if (check_login_appbk() == false) {
            redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login");
        }

        const config = {
            headers: {Authorization: `Bearer ${Token_get_appbk()}`}
        };

       //// Setloadinghkfullshow("true");


        let form_data = document.querySelector('#form_data_header');
        if (form_data != null) {
            let formDatanew = new FormData(form_data);
            let data_serialize = new URLSearchParams(formDatanew).toString();



            axios.post(`${clientconfigwebapp.siteurl}api/v1/panel-dr/status-online-change-pr/`, data_serialize, config)
                .then((res) => {

                  ///  Setloadinghkfullshow("false");

                    if (String(res.data).length > 0) {

                        if (String(res.data.data) == "login-show") {
                            redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login");
                        }





                        if (String(res.data.status) == "success") {
                           /// set_active_tab_curent('tab3');
                        } else {

                            if (String(res.data.msgwebapp).length > 0) toast.error(res.data.msgwebapp, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });

                        }


                    } else {
                        if (String(res.data.msgwebapp).length > 0) toast.error(res.data.msgwebapp, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                }).catch((err) => {
                ////Setloadinghkfullshow("false");
                toast.error('Error connecting to the server, code 345345', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });


        }


    }


  return (
    <Fragment>
        <header className='header_container flexRowBetCenter'>
        <span className='menu_btn' onClick={menuOpenHandler}>
            <Menu/>
        </span>

            <MenuIcon  className={"menu_icon_mobile"} style={{width:"30px",height:"30px"}} sx={{mr: 2, display: {sm: 'none'}}} onClick={handleDrawerToggle}/>
            <img className={"header-logo"} src={logo} alt="logotop"/>





            <div className={"flexRowBetCenter"} >


                { props.type_user == "doctor" ? (<Fragment>
                    <div style={{"marginRight":"8px"}}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <span className={"header-status-btntitle"}>Online status</span>

                            <AntSwitch
                                checked={statusOnline === 'online'}
                                onChange={handleUpdateStatusOnline}
                                name="status_online_change"
                                inputProps={{'aria-label': 'ant design'}}
                            />
                        </Stack>
                    </div>
                </Fragment>) : (<Fragment></Fragment>)
                }


                <div className='flexRowBetCenter' onClick={userMenuOpen}>
                    <ul className='flecColStartStart user_menu'>

                        {props.type_user == "doctor" ? (<Fragment>
                            <li><a href={String(clientconfigwebapp.webappurl) + "panel/profile"}>Edit Profile</a>
                            </li>
                        </Fragment>) : (<Fragment>
                            <li><a href={String(clientconfigwebapp.webappurl) + "panel/profile"}>Edit Profile</a></li>
                        </Fragment>)
                        }


                        {props.type_user == "doctor" ? (<Fragment>
                            <li><a href={String(clientconfigwebapp.webappurl) + "login-register/doctor"}>Logout</a></li>
                        </Fragment>) : (<Fragment>
                            <li><a href={String(clientconfigwebapp.webappurl) + "login-register"}>Logout</a></li>
                        </Fragment>)
                        }


                    </ul>
                    <img className={"header-image-profile"} src={prof_img}  alt="profile"/>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="0 0 16 17"
                         fill="none">
                        <path d="M4 6.03125L8 10.0313L12 6.03125" stroke="#818288" stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>


        </header>
    </Fragment>
  );
}

export default Header;