    import axios from "axios";
    import React, {useEffect, useState, useRef, Fragment} from "react";
    import {toast} from "react-toastify";

    import clientconfigwebapp, {
    empty_bk,
    convert_to_english_strhk,
    check_login_appbk, Token_get_appbk,
} from '../../clientconfigwebapp';
    import {
    Icon_Attatch_btn_file, Icon_btn_send,
    Icon_send_Tiket
} from "../../assets/icones/icones";



    const File_Ecu_Box_upload = (props) => {


        const [text_message, settext_message] = useState('');

        const [errorMessage, setErrorMessage] = useState('');


        /*  start code file_upload  */
        const [file, setFile] = useState();
        const [file_name_get, set_file_name] = useState('');
        const uploadRef = useRef();
        const statusRef = useRef();
        const loadTotalRef = useRef();
        const progressRef = useRef();
        const [attach_file_get, set_attach_file] = useState('');
        const [show_hide_pogressbar, setshow_hide_pogressbar] = useState('none');
        /*  end code file_upload  */

        const Handler_Chanche_text_message = (event) => {
            settext_message(String(convert_to_english_strhk(event.target.value)));
            event.target.style.height = 'auto';
            event.target.style.height = `${event.target.scrollHeight}px`;
        }

        const UploadFile = () => {
            const file = uploadRef.current.files[0];
            console.log(file, "filefilefile");
            setFile(URL.createObjectURL(file));
            set_file_name(file.name);
            var formData = new FormData();
            formData.append("fileattach", file);
            var xhr = new XMLHttpRequest();
            xhr.upload.addEventListener("progress", ProgressHandler, false);
            xhr.addEventListener("load", SuccessHandler, false);
            xhr.addEventListener("error", ErrorHandler, false);
            xhr.addEventListener("abort", AbortHandler, false);
            xhr.open("POST", String(clientconfigwebapp.host_dl_get) + "upload.php");
            xhr.send(formData);
            open_progress_Modal();
        };

        const ProgressHandler = (e) => {
            set_attach_file("");
            setshow_hide_pogressbar("flex");
            var percent = (e.loaded / e.total) * 100;
            progressRef.current.value = Math.round(percent);
            statusRef.current.innerHTML = Math.round(percent) + "%";
        };

        const SuccessHandler = (e) => {
            set_attach_file("");
            var respons_get = e.target.response;
            progressRef.current.value = 0;
            statusRef.current.innerHTML = "0%";
            console.log(respons_get, "respons_get");

            close_progress_Modal();
            if (String(respons_get).length > 0) {
                var respons_get_json = JSON.parse(respons_get);
                if (String(respons_get_json.data).length > 0) {
                    if (String(respons_get_json.data) == "success" && !empty_bk(String(respons_get_json.file_name_get))) {
                        set_attach_file(String(respons_get_json.file_name_get));
                        progressRef.current.value = 100;
                        statusRef.current.innerHTML = "100%";

                        if (String(respons_get_json.msgwebapp).length > 0) {
                            toast.success(respons_get_json.msgwebapp, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    } else {
                        if (String(respons_get_json.msgwebapp).length > 0) {
                            toast.error(respons_get_json.msgwebapp, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                } else {
                    if (String(respons_get_json.msgwebapp).length > 0) {
                        toast.error(respons_get_json.msgwebapp, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            } else {
                toast.error("File upload error, error code 654754", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        };

        const ErrorHandler = () => {
            close_progress_Modal();
            statusRef.current.innerHTML = "upload failed!!";
            setshow_hide_pogressbar("none");
            set_attach_file("");
            toast.error("Server connection error, check your internet. Error code 567567", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        };

        const AbortHandler = () => {
            close_progress_Modal();
            statusRef.current.innerHTML = "upload aborted!!";
            setshow_hide_pogressbar("none");
            set_attach_file("");
            toast.error("Server connection error, check your internet. Error code 789789", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        };


        const open_progress_Modal = () => {
            document.getElementById("progressModal").classList.remove("hidden");
            if (progressRef.current && statusRef.current) {
                progressRef.current.value = 0;
                statusRef.current.innerText = "0";
            }
        };

        const close_progress_Modal = () => {
            document.getElementById("progressModal").classList.add("hidden");
        };


        const Handler_send_ticket_new = (type_req) => {
            // if (check_login_appbk() == false) {
            //     router.replace('/login-reg');
            // }


            const config = {
                headers: {Authorization: `Bearer ${Token_get_appbk()}`}
            };

            let form_data = document.querySelector('#form_data_file');
            if (form_data != null) {

                let formDataObject = new FormData(form_data);



                formDataObject.append('type_file', 'attach');

                let data_serialize = new URLSearchParams(formDataObject).toString();

                axios.post(`${clientconfigwebapp.siteurl}api/v1/panel/request-save-pr/`, data_serialize, config)
                    .then((res) => {
                        if (String(res.data.data).length > 0) {
                            // if (String(res.data.data) == "login-show") {
                            //     router.replace('/login-reg');
                            // }


                            if (String(res.data.status) == "success") {

                                settext_message("");


                                if (String(res.data.msgwebapp).length > 0) {
                                    toast.success(res.data.msgwebapp, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                }


                            } else {
                                if (String(res.data.msgwebapp).length > 0)
                                    toast.error(res.data.msgwebapp, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                            }
                        } else {
                            if (String(res.data.msgwebapp).length > 0)
                                toast.error(res.data.msgwebapp, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                        }
                    }).catch((err) => {
                    toast.error('Server connection error, error code 45346', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            }
        }

        return (
            <Fragment>
                <div id="result_req_saved_pr"/>


                <input type={"text"} value={String(attach_file_get)} name="attach_file_get" style={{display: "none"}}/>


                <div className="flex justify-center items-center relative">
                    <label htmlFor="text_msg_get"
                           className="absolute left-[5px] top-[4px]  text-[12px] lg:text-[14px] text-[red]">{errorMessage}</label>
                    <textarea
                        style={{"border":"solid 1px #ebebeb"}}
                        name="text_message_get"
                        value={text_message}
                        onChange={Handler_Chanche_text_message}
                        id="text_msg_get"
                        className="flex text-left justify-end h-[140px] rounded-[6px] w-full p-[15px]  placeholder:text-gray-600 bg-[#f6f6f6] border-0 text-gray-900 text-sm focus:ring-0 focus:outline-none "
                        placeholder="Your message..."
                    />
                </div>


                <div className={"flex flex-row mt-[15px]"}>
                    <label className={"w-[50%] lg:w-[24%] bg-[#2b3034] cursor-pointer hover:bg-[#0a5df1] rounded-[7px] text-center py-[13px] text-[white] flex flex-row-reverse content-center items-center justify-center gap-x-[11px] transition-all duration-300 "}
                           htmlFor={"attach-file"}>
                        <input type="file" id={"attach-file"} name="fileget"
                               className={"input-file-btn"} ref={uploadRef}
                               onChange={UploadFile}/>
                        File
                        <Icon_Attatch_btn_file fill={"#b0b0b0"} className={"w-[25px] h-[25px]"}   />
                    </label>


                    <button onClick={Handler_send_ticket_new.bind(null, 'attach')} type={"button"}
                            className="w-[50%] lg:w-[24%]  bg-[#2b3034] cursor-pointer hover:bg-[#0a5df1] rounded-[7px] text-center py-[13px] text-[white] flex flex-row-reverse content-center items-center justify-center gap-x-[11px] transition-all duration-300 bg-[#0d8c84] ml-[11px]">
                        <Icon_btn_send fill={"#b0b0b0"} className={"w-[25px] h-[25px]"}   />
                        Send
                    </button>


                </div>


                <div id="progressModal"
                     className="fixed inset-0 z-50  hidden  bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-[100%] lg:w-[30%]">
                        <button type={"button"} onClick={close_progress_Modal} id="closeModal"
                                className="text-red-500 hover:text-red-700 font-bold">X
                        </button>
                        <div>
                            <progress
                                ref={progressRef}
                                value="0"
                                max="100"
                                className="ltr w-full h-4 bg-gray-200 rounded-lg overflow-hidden"
                            >
                            <div className="bg-blue-500 h-full"></div>
                            </progress>
                            <span className="pers text-gray-700 text-sm mt-2 block text-center text-[26px]"
                                  ref={statusRef}>0</span>
                        </div>
                    </div>
                </div>


            </Fragment>
        )
    };

    export default File_Ecu_Box_upload;
