import axios from "axios";
import React, {useEffect, useState, useRef, Fragment} from "react";

import {useNavigate} from "react-router-dom"
import clientconfigwebapp, {
    empty_bk,
    convert_to_english_strhk,
    scrollTo_uphk,
    modal_login_reg_bk_show, modal_login_reg_all_hide, redirectbh_typeb,
} from '../../clientconfigwebapp';
import {ToastContainer, toast} from 'react-toastify';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import Logo_site from "../../assets/images/logo.png";


import ReactFullPageLoadinghk from "../../components/layaout/LoadingFullpagehk";
import {Icon_Email, Icon_Lock_Pass} from "../../assets/icones/icones";


const serialize = require('form-serialize');


const Modal_Login = (props) => {


    const navigate = useNavigate();
    const [loadinghkfullshow, Setloadinghkfullshow] = useState("false");


    const [password_login, set_password_login] = useState('');


    const [email_address_login_reg_check, set_email_address_login_reg_check] = useState('');


    useEffect(() => {
        scrollTo_uphk();


    }, []);

    /* timer end*/


    const Handle_register_show_bk = () => {
        modal_login_reg_bk_show("regshow", "");
    };

    const password_login_Handler = (event) => {
        set_password_login(convert_to_english_strhk(event.target.value));
    }


    /* start login_by_pasword_complate  */


    const Handler_phone_login_reg_check = (event) => {
        set_email_address_login_reg_check(convert_to_english_strhk(event.target.value));
    }
    const Handler_Login_req = () => {

        if (email_address_login_reg_check === '') {
            toast.error('Email address not entered', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return false;
        } else if (password_login === '') {
            toast.error('Password not entered', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return false;

        }


        let form_data = document.querySelector('#form_Registerpagewebapp');
        if (form_data != null) {
            let data_serialize = serialize(form_data);


            Setloadinghkfullshow("true");


            axios.post(`${clientconfigwebapp.siteurl}api/v1/req-login-pr`, data_serialize)
                .then((res) => {

                    Setloadinghkfullshow("false");


                    if (!empty_bk(res.data.data)) {

                        localStorage.setItem('tokenacess', res.data.data);
                        if (!empty_bk(res.data.first_name)) {
                            localStorage.setItem('first_name_Get', res.data.first_name);
                        }
                        if (!empty_bk(res.data.last_name)) {
                            localStorage.setItem('last_name_Get', res.data.last_name);
                        }

                        modal_login_reg_all_hide();


                        redirectbh_typeb(String(clientconfigwebapp.webappurl))


                    } else {
                        toast.error(res.data.msgwebapp, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                    }

                }).catch((err) => {
                Setloadinghkfullshow("false");
                toast.error('Error connecting to the server, code 5431', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                console.error(err, "is error 5431!");

            });


        } else {

            alert('errcode:14');
        }


    }

    /* enddd login_by_password_complate  */

    return (
        <Fragment>

            <ReactFullPageLoadinghk loadinghk={loadinghkfullshow}/>


            <div id="login_reg_check_view_modal" className="flexColStartStart"
                 style={{"display": "block"}}>


                <div className="flex items-center justify-center flex-col h-screen">


                    <img className="w-1/3 mb-8" src={Logo_site} alt="Logo"/>


                    <span className={"text-[19px] font-['Poppins'] mb-[11px] font-semibold"}>Welcome</span>

                    <section
                        className="flex flex-col w-[45%] max-md:ml-0
                        max-md:w-full border-[solid] border-[1px] border-[#ededed] p-[16px] pt-[61px] pb-[61px] rounded-[10px] pr-[4%] pl-[4%]">
                        <div className="flex flex-col max-md:mt-10">


                            <TextField
                                placeholder="Email"
                                id="email_address"
                                name="email_address"
                                onChange={Handler_phone_login_reg_check}
                                variant="filled"
                                className="inputboxloginreg"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon_Email className="inputboxloginreg-icon w-8 h-8" />
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <TextField
                                placeholder="Password"
                                id="password_login"
                                name="password_login"
                                variant="filled"
                                className="inputboxloginreg !mt-4"
                                onChange={password_login_Handler}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon_Lock_Pass className="inputboxloginreg-icon w-8 h-8" />
                                        </InputAdornment>
                                    ),
                                }}
                                type="password"
                            />

                            <a href="#" className="mt-5 text-xs leading-3 text-left text-sky-500">Forgot
                                Password</a>

                            <button
                                type="button"
                                onClick={Handler_Login_req}
                                className="mt-5 py-3 text-lg font-medium leading-8 text-white rounded-lg max-md:px-5 max-md:mt-10 btn-green-dim-bh"
                            >
                                Log In
                            </button>

                            <p className="mt-5 text-sm leading-5 text-center text-lime-500">
                                <span className="text-zinc-500">Don't have an account?</span>
                                <button
                                    onClick={Handle_register_show_bk}
                                    type="button"
                                    className="text-blue-600 font-bold"
                                >
                                    Sign up
                                </button>
                            </p>


                        </div>
                    </section>


                </div>


            </div>


        </Fragment>
    );
}


export default Modal_Login;