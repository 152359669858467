import axios from "axios";
import React, {useEffect, useState, useRef, Fragment} from "react";
import {Link, NavLink, useParams, useNavigate, useLocation} from "react-router-dom"
import {render} from "react-dom";
import clientconfigwebapp, {
    empty_bk, convert_to_english_strhk, scrollTo_uphk, check_login_appbk, Token_get_appbk, redirectbh_typeb,
} from '../../clientconfigwebapp';
import {ToastContainer, toast} from 'react-toastify';

import Master_Panel from "../panel/Master_Panel";


import ReactFullPageLoadinghk from "../../components/layaout/LoadingFullpagehk";

import image_serv from "../../assets/images/img-serv.png"
import {
    Icon_Agriculture_tab,
    Icon_Boat_tab,
    Icon_car_tab, Icon_jetski_tab,
    Icon_Motor_tab,
    Icon_truck_tab,
    Information
} from "../../assets/icones/icones";
import File_Ecu_Box_upload from "../../components/files_Upload/File_Ecu_Box_upload";




const Files_Page = () => {
    const location = useLocation()
    const {tab_show_by_url} = useParams();


    const location_get_urlbk = useLocation();
    const redirect_navigate = useNavigate();
    const [loadinghkfullshow, Setloadinghkfullshow] = useState("false");

    /*  start profile */
    const [wallet_balance, setData_wallet_balance] = useState('0');
    const [first_name_profile, setData_first_name_profile] = useState('');
    const [last_name_profile, setData_last_name_profile] = useState('');
    const [profile_src, setData_profile_src] = useState('');

    /*  end profile */
    const [active_tab_curent, set_active_tab_curent] = useState("3")

    const [Data_Full_Get, set_Data_Full_Get] = useState([]);

    const [Data_brands_Get, set_Data_brands] = useState([]);
    const [Data_models_Get, set_Data_models] = useState([]);
    const [Data_engines_Get, set_Data_engines] = useState([]);


    const [selectedModel, setSelectedModel] = useState('');
    const [generations, setGenerations] = useState([]);



    const [selectedBrandData, setSelectedBrandData] = useState(null);
    const [selectedModelData, setSelectedModelData] = useState(null);
    const [selectedEngineData, setSelectedEngineData] = useState(null);




    useEffect(() => {
        scrollTo_uphk();
        if (check_login_appbk() == false) {
            redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login" );
            return false;
        }

        ///if (String(tab_show_by_url) == "tab1" || String(tab_show_by_url) == "tab2") set_active_tab_curent(String(tab_show_by_url));
         set_active_tab_curent(String(tab_show_by_url));


        Load_api_page("brands",tab_show_by_url);

    }, [location_get_urlbk]);


    const handle_Change_brand = (event) => {
        const value_item_option = event.target.value;
        const selectedItem = Data_brands_Get.find(item => item.id === parseInt(value_item_option));
        setSelectedBrandData(selectedItem); // ذخیره کل داده
        Load_api_page("models", String(value_item_option));
    };

    const handle_Change_model = (event) => {
        const value_item_option = event.target.value;
        const selectedItem = Data_models_Get.find(item => item.model.id === parseInt(value_item_option));
        setSelectedModel(value_item_option);
        console.log(selectedItem,"setSelectedModelData")
        setSelectedModelData(selectedItem); // ذخیره کل داده
        if (selectedItem && selectedItem.generations) {
            setGenerations(selectedItem.generations);
        } else {
            setGenerations([]);
        }
    };

    const handle_Change_Engine = (event) => {
        const value_item_option = event.target.value;
        const selectedItem = Data_engines_Get.find(item => item.id === parseInt(value_item_option));
        setSelectedEngineData(selectedItem); // ذخیره کل داده
        Load_api_page("engines", String(value_item_option));
    };


    // const handle_Change_brand = (event) => {
    //     const value_item_option = event.target.value;
    //     Load_api_page("models",String(value_item_option))
    // };
    //
    //
    //
    //
    // const handle_Change_model = (event) => {
    //     const value_item_option = event.target.value;
    //     setSelectedModel(value_item_option);
    //
    //     // پیدا کردن مدل انتخاب شده
    //     const selectedItem = Data_models_Get.find(item => item.model.id === parseInt(value_item_option));
    //     if (selectedItem && selectedItem.generations) {
    //         setGenerations(selectedItem.generations);
    //     } else {
    //         setGenerations([]);
    //     }
    // };
    //
    //
    // const handle_Change_Engine = (event) => {
    //     const value_item_option = event.target.value;
    //     Load_api_page("engines",String(value_item_option))
    // };



    const Load_api_page = (type_data,id_get) => {

        if (check_login_appbk() == false) {
            redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login");
        }

        const config = {
            headers: {Authorization: `Bearer ${Token_get_appbk()}`}
        };

        Setloadinghkfullshow("true");

        const params = new URLSearchParams();
        params.append('type_data', String(type_data));
        params.append('id', String(id_get));


        axios.post(`${clientconfigwebapp.siteurl}api/v1/panel/get-all-ecu-info/`, params, config)
            .then((res) => {
                Setloadinghkfullshow("false");
                ///console.log(res.data);
                if (String(res.data).length > 0) {

                    if (String(res.data.data) == "login-show") {
                        redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login");
                    }


                    if (String(res.data.wallet_inventory).length > 0) {
                        setData_wallet_balance(res.data.wallet_inventory);
                    }

                    if (!empty_bk(res.data.first_name_profile)) {
                        setData_first_name_profile(res.data.first_name_profile);
                        localStorage.setItem('first_name_Get', res.data.first_name_profile);
                    }
                    if (!empty_bk(res.data.last_name_profile)) {
                        setData_last_name_profile(res.data.last_name_profile);
                        localStorage.setItem('last_name_Get', res.data.last_name_profile);
                    }


                    if (!empty_bk(res.data.profile_src)) {
                        setData_profile_src(res.data.profile_src);
                    }




                   if (String(res.data.data).length > 0) {

                       if(type_data=="brands"){  set_Data_brands(res.data.data); }

                       if(type_data=="models"){  set_Data_models(res.data.data); }

                       if(type_data=="engines"){  set_Data_engines(res.data.data); }



                   }





                } else {
                    if (String(res.data.msgwebapp).length > 0) toast.error(res.data.msgwebapp, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            }).catch((err) => {
            Setloadinghkfullshow("false");
            toast.error('Error connecting to the server, code 435346', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });


    }



    return (<Fragment>

        <Master_Panel wallet_balance={wallet_balance} first_name_profile={first_name_profile} last_name_profile={last_name_profile} profile_src={profile_src}>
            <ReactFullPageLoadinghk loadinghk={loadinghkfullshow}/>
            <ToastContainer/>


            <form id={"form_data_file"} name={"form_data_file"} encType="multipart/form-data"
                  style={{width: "100%"}}>


                <div className={"bg-[white] rounded-[13px]"}>


                    <div className="bg-[#8AD6D1] flex flex-wrap  pt-4 rounded-tr-[13px] rounded-tl-[13px] ">

                        <div className="w-full lg:w-6/12 flex-col content-center items-center p-[18px] justify-center">
                            <h1 className={"text-[white] text-left text-[23px] mb-[13px] leading-[1.6]"}>Online tuning
                                File service | Chip Tuning file</h1>
                            <p className={"text-[white] text-left text-[13px] mb-[13px] leading-[1.6]"}>
                                With this service, you can get dyno tested tuning file from our company for petrol
                                and
                                diesel. You can also have ECU solutions such as DPF off, EGR off and etc.
                            </p>
                        </div>

                        <div
                            className="w-full flex lg:w-6/12 flex-col content-center items-center p-[18px] justify-center relative">

                            <img src={image_serv}/>
                        </div>


                    </div>


                    <div className={" w-[93%] ml-[3%] pb-[200px]"}>

                        <div className={" w-full py-[20px] px-[15px]"}>
                        <span
                            className={"w-full block text-[23px] text-left text-[black]"}>Please Select Your Vehicle</span>
                            <span className={"w-full block text-[13px] text-left text-[black]"}>($70 is the Price of a tuning file with 30 month guarantee)</span>
                        </div>


                        <div
                            className='flex flex-row flex-wrap content-center items-center justify-center bg-[#EBEBEB] p-[9px] rounded-[10px] gap-x-[24px] gap-y-[10px]'>


                            <NavLink to={"/panel/files/3"} reloadDocument={true}
                                     className={`flex flex-row justify-center items-center content-center`}
                                     style={String(active_tab_curent) == "3" ? {"borderBottom": "solid 5px #0d8c84"} : null}>
                                <Icon_car_tab width={"30px"} height={"30px"}/><span
                                className={"text-[#0D8C84] ml-[5px]"}>Car</span>
                            </NavLink>

                            <NavLink to={"/panel/files/1"} reloadDocument={true}
                                     onClick={() => set_active_tab_curent("1")}
                                     className={`flex flex-row justify-center items-center content-center`}
                                     style={String(active_tab_curent) == "1" ? {"borderBottom": "solid 5px #0d8c84"} : null}>
                                <Icon_truck_tab width={"30px"} height={"30px"}/><span
                                className={"text-[#0D8C84] ml-[5px]"}>Trucks</span>
                            </NavLink>


                            <NavLink to={"/panel/files/8"} reloadDocument={true}
                                     onClick={() => set_active_tab_curent("8")}
                                     className={`flex flex-row justify-center items-center content-center`}
                                     style={String(active_tab_curent) == "8" ? {"borderBottom": "solid 5px #0d8c84"} : null}>
                                <Icon_Motor_tab width={"30px"} height={"30px"}/><span
                                className={"text-[#0D8C84] ml-[5px]"}>Motor/Quad</span>
                            </NavLink>


                            <NavLink to={"/panel/files/10"} reloadDocument={true}
                                     onClick={() => set_active_tab_curent("10")}
                                     className={`flex flex-row justify-center items-center content-center`}
                                     style={String(active_tab_curent) == "10" ? {"borderBottom": "solid 5px #0d8c84"} : null}>
                                <Icon_Boat_tab width={"30px"} height={"30px"}/><span
                                className={"text-[#0D8C84] ml-[5px]"}>Boat / Marine</span>
                            </NavLink>


                            <NavLink to={"/panel/files/7"} reloadDocument={true}
                                     onClick={() => set_active_tab_curent("7")}
                                     className={`flex flex-row justify-center items-center content-center`}
                                     style={String(active_tab_curent) == "7" ? {"borderBottom": "solid 5px #0d8c84"} : null}>
                                <Icon_Agriculture_tab width={"30px"} height={"30px"}/><span
                                className={"text-[#0D8C84] ml-[5px]"}>Agriculture</span>
                            </NavLink>


                            <NavLink to={"/panel/files/6"} reloadDocument={true}
                                     onClick={() => set_active_tab_curent("6")}
                                     className={`flex flex-row justify-center items-center content-center`}
                                     style={String(active_tab_curent) == "6" ? {"borderBottom": "solid 5px #0d8c84"} : null}>
                                <Icon_jetski_tab width={"30px"} height={"30px"}/><span
                                className={"text-[#0D8C84] ml-[5px]"}>JetSki</span>
                            </NavLink>


                        </div>


                        <div className="flex flex-wrap  pt-4">

                            <div className="w-full lg:w-3/12 px-2 mt-2">
                                <select name={"brand"} className="w-full p-2 border rounded"
                                        onChange={handle_Change_brand}>
                                    <option value={""}>Select</option>
                                    {Data_brands_Get && Data_brands_Get.map(item => (
                                        <option key={item.id} value={String(item.id)}>
                                            {item.value}
                                        </option>
                                    ))}
                                </select>
                            </div>


                            <div className="w-full lg:w-3/12 px-2 mt-2">
                                <select name={"model"} className="w-full p-2 border rounded"
                                        onChange={handle_Change_model}>
                                    <option value={""}>Select Model</option>
                                    {Data_models_Get && Data_models_Get.map(item => (
                                        <option key={item.model.id} value={String(item.model.id)}>
                                            {item.model.value}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="w-full lg:w-3/12 px-2 mt-2">
                                <select name={"generation"} className="w-full p-2 border rounded"
                                        onChange={handle_Change_Engine}>
                                    <option value={""}>Select Generation</option>
                                    {generations.map(generation => (
                                        <option key={generation.id} value={String(generation.id)}>
                                            {generation.naam}
                                        </option>
                                    ))}
                                </select>
                            </div>


                            <div className="w-full lg:w-3/12 px-2 mt-2">
                                <select name={"engines"} className="w-full p-2 border rounded"
                                        onChange={handle_Change_brand}>
                                    <option value={""}>Select</option>
                                    {Data_engines_Get && Data_engines_Get.map(item => (
                                        <option key={item.id} value={String(item.id)}>
                                            {item.value}
                                        </option>
                                    ))}


                                </select>
                            </div>


                        </div>


                        {selectedModelData && selectedModelData.model.naam && Data_engines_Get && Data_engines_Get.length > 0 &&(


                            <div className="flex flex-wrap pt-4">
                                <div className="w-full lg:w-4/12 px-2 mt-2">
                                    <img
                                        src={"https://api.caracaltech.com/static/models/webp/" + String(selectedModelData.model.naam) + ".webp"}
                                        className="w-[100%] h-auto"
                                        alt="Model Image"
                                         style={{"border":"solid 1px #e5e5e5"}}
                                    />
                                </div>

                                <div className="w-full lg:w-8/12 px-2 mt-2">
                                    <File_Ecu_Box_upload   />
                                </div>
                            </div>
                        )}


                    </div>


                </div>


            </form>


        </Master_Panel>
        </Fragment>
    );
}


export default Files_Page;