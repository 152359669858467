import axios from "axios";
import React, {useEffect, useState, useRef, Fragment} from "react";
import {Link, NavLink, useParams, useNavigate, useLocation} from "react-router-dom"
import {render} from "react-dom";
import clientconfigwebapp, {
    empty_bk,
    convert_to_english_strhk,
    scrollTo_uphk,
    check_login_appbk,
    Token_get_appbk,
    redirectbh_typeb,
    getLengt_arraybk,
} from '../../clientconfigwebapp';



import {Icon_Chat_List_users, Icon_Trash_Chat} from "../../assets/icones/icones";
const Chat_List_Right = (props) => {



 return (<Fragment>

         <div className="flex items-center justify-center">
             <NavLink className={"btn-new-chat text-[13px]"} to={"/"} reloadDocument={true}>
                 + New chat
             </NavLink>
         </div>

         <div className={"list_users_tikets"}>
             {
                 props.data_list && props.data_list.length > 0 && (
                     <Fragment>
                         <div>
                             <div className="pb-1 pt-1.5 item_list_users_tikets">
                                 <span className="text-gray-500 text-[18px]">Chat history</span>
                                 {/*<Icon_Trash_Chat/>*/}
                             </div>

                             {props.data_list.map((item_post, index) => {
                                 return (
                                     <NavLink
                                         to={"/chat/" + String(item_post.post_id)}
                                         reloadDocument={true}
                                         className={`item_list_users_tikets ${item_post.active_current_chat === 'yes' ? 'active-chat-curent' : ''}`}
                                         key={index}>
                                         <Icon_Chat_List_users/>

                                         <div className="flex flex-row items-center justify-between w-full">
                                                            <span className={"font-normal block pb-[9px] pt-[10px] text-[#000000] text-[13px] font-['Poppins'] pl-[9px] w-[88%] line-clamp-1 h-[32px] w-[88px]"}>{String(item_post.title_chat)}</span>
                                             <Icon_Trash_Chat/>
                                         </div>
                                     </NavLink>
                                 );
                             })}
                         </div>
                     </Fragment>
                 )
             }
         </div>

     </Fragment>
 );
}


export default Chat_List_Right;