import axios from "axios";
import React, {useEffect, useState, useRef, Fragment} from "react";
import {Link, NavLink, useParams, useNavigate, useLocation} from "react-router-dom"
import {render} from "react-dom";
import clientconfigwebapp, {
    empty_bk, convert_to_english_strhk, scrollTo_uphk, check_login_appbk, Token_get_appbk, redirectbh_typeb,
} from '../../clientconfigwebapp';
import {ToastContainer, toast} from 'react-toastify';

import Master_Panel from "../panel/Master_Panel";


import "../../components/assets/css/profile.css"

import ReactFullPageLoadinghk from "../../components/layaout/LoadingFullpagehk";


import Tab1_view from "../../components/Panel_Simple/Profile_edit/Tab1_view";


import Grid from '@mui/material/Grid';

import {Back, Information} from '../../assets/icones/icones';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';


const serialize = require('form-serialize');


const Profile_Edit = () => {
    const location = useLocation()
    const {tab_show_by_url} = useParams();


    const location_get_urlbk = useLocation();
    const redirect_navigate = useNavigate();
    const [loadinghkfullshow, Setloadinghkfullshow] = useState("false");

    /*  start profile */
    const [wallet_balance, setData_wallet_balance] = useState('0');
    const [first_name_profile, setData_first_name_profile] = useState('');
    const [last_name_profile, setData_last_name_profile] = useState('');
    const [list_order_get, setData_list_order] = useState([]);
    const [profile_src, setData_profile_src] = useState('');

    /*  end profile */
    const [active_tab_curent, set_active_tab_curent] = useState("tab1")

    const [Data_Full_Get, set_Data_Full_Get] = useState([]);


    const [state_user_input, setstate_user_input] = useState('');
    const [city_user_input, setcity_user_input] = useState('');
    const [street_user_input, set_street_user_input] = useState('');
    const [zip_code_user_input, set_zip_code_user_input] = useState('');
    const [list_data_services, setData_list_services] = useState([]);


    const [list_data_my_prices_services, setData_list_my_prices_services] = useState([]);


    useEffect(() => {
        scrollTo_uphk();
        if (check_login_appbk() == false) {
            redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login" );
            return false;
        }

        if (String(tab_show_by_url) == "tab1" || String(tab_show_by_url) == "tab2") set_active_tab_curent(String(tab_show_by_url));


        Load_api_page();

    }, [location_get_urlbk]);

    const Load_api_page = () => {

        if (check_login_appbk() == false) {
            redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login");
        }

        const config = {
            headers: {Authorization: `Bearer ${Token_get_appbk()}`}
        };

        Setloadinghkfullshow("true");

        const params = new URLSearchParams();
        params.append('page', "edit_profile");



        axios.post(`${clientconfigwebapp.siteurl}api/v1/dashboard-get`, params, config)
            .then((res) => {
                Setloadinghkfullshow("false");
                console.log(res.data);
                if (String(res.data).length > 0) {

                    if (String(res.data.data) == "login-show") {
                        redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login");
                    }



                    set_Data_Full_Get(res.data);

                    if (String(res.data.wallet_inventory).length > 0) {
                        setData_wallet_balance(res.data.wallet_inventory);
                    }

                    if (!empty_bk(res.data.first_name_profile)) {
                        setData_first_name_profile(res.data.first_name_profile);
                        localStorage.setItem('first_name_Get', res.data.first_name_profile);
                    }
                    if (!empty_bk(res.data.last_name_profile)) {
                        setData_last_name_profile(res.data.last_name_profile);
                        localStorage.setItem('last_name_Get', res.data.last_name_profile);
                    }


                    if (!empty_bk(res.data.profile_src)) {
                        setData_profile_src(res.data.profile_src);
                    }


                    if (res.data.data_province_city && res.data.data_province_city.length > 0) {
                        setProvinces(res.data.data_province_city);
                    }

                    if (res.data.state) {
                        setstate_user_input(res.data.state);
                    }

                    if (res.data.city) {
                        setcity_user_input(res.data.city);
                    }

                    // setProvinces(res.data.data_province_city);
                    // setstate_user_input(res.data.state);
                    // setcity_user_input(res.data.city);


                    if (!empty_bk(res.data.street)) {
                        set_street_user_input(res.data.street);
                    }
                    if (!empty_bk(res.data.zip_code)) {
                        set_zip_code_user_input(res.data.zip_code);
                    }


                    if (String(res.data.services).length > 0) {
                        setData_list_services(res.data.services);
                    }

                    if (String(res.data.my_prices_services).length > 0) {
                        setData_list_my_prices_services(res.data.my_prices_services);
                    }


                } else {
                    if (String(res.data.msgwebapp).length > 0) toast.error(res.data.msgwebapp, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            }).catch((err) => {
            Setloadinghkfullshow("false");
            toast.error('Error connecting to the server, code 435346', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });


    }

    const Handler_Chanche_street_input = (event) => {
        set_street_user_input(convert_to_english_strhk(event.target.value));
    }

    const Handler_Chanche_zip_code_input = (event) => {
        set_zip_code_user_input(convert_to_english_strhk(event.target.value));
    }



    const Handler_saved_curent_tab2 = () => {

        if (check_login_appbk() == false) {
            redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login");
        }

        const config = {
            headers: {Authorization: `Bearer ${Token_get_appbk()}`}
        };

        Setloadinghkfullshow("true");


        let form_data = document.querySelector('#form_data_profile');
        if (form_data != null) {
            let formDatanew = new FormData(form_data);
            formDatanew.append("step", "2");
            let data_serialize = new URLSearchParams(formDatanew).toString();



            axios.post(`${clientconfigwebapp.siteurl}api/v1/panel/profile-edit-pr/`, data_serialize, config)
                .then((res) => {

                    Setloadinghkfullshow("false");

                    if (String(res.data).length > 0) {

                        if (String(res.data.data) == "login-show") {
                            redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login");
                        }



                        if (String(res.data.status) == "success") {
                            set_active_tab_curent('tab3');
                        } else {

                            if (String(res.data.msgwebapp).length > 0) toast.error(res.data.msgwebapp, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });

                        }


                    } else {
                        if (String(res.data.msgwebapp).length > 0) toast.error(res.data.msgwebapp, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                }).catch((err) => {
                Setloadinghkfullshow("false");
                toast.error('Error connecting to the server, code 2342326', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });


        }


    }



////// Provinces AND Cities START CODE
    const [provinces, setProvinces] = useState([]);
    const [selectedProvince, setSelectedProvince] = useState('');
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');

    useEffect(() => {
        setDefaultLocation(state_user_input, city_user_input);
    }, [provinces]);

    const handleProvinceChange = (event) => {
        const provinceName = event.target.value;
        setDefaultLocation(provinceName);
    };

    const setDefaultLocation = (provinceName, cityName = '') => {
        const province = provinces.find(p => p.Province === provinceName);
        ////console.log(province,"aaaaaaaaaa")
        if (province) {
            ///console.log(provinceName,"provinceNameprovinceName")
            setSelectedProvince(provinceName);
            setCities(province.City);
            ///console.log(province.City,"province.City")
            setSelectedCity(cityName || province.City[0] || '');
        }
    };
////// Provinces AND Cities END CODE

    return (<Fragment>

        <Master_Panel wallet_balance={wallet_balance} first_name_profile={first_name_profile} last_name_profile={last_name_profile} profile_src={profile_src}>
            <ReactFullPageLoadinghk loadinghk={loadinghkfullshow}/>
            <ToastContainer/>


            <form id={"form_data_profile"} name={"form_data_profile"} encType="multipart/form-data"
                  style={{width: "100%"}}>


                <section className='flexColStartStart information_container'>

                    <Link to='/panel/dashboard' className='box-back-link'>
                        <Back/>
                        <span></span>
                        Profile
                    </Link>

                    <section className='flexColStartStart information_wrapper' style={{paddingBottom: "5px"}}>


                        <div style={{ display: String(active_tab_curent) === 'tab3' ? 'none' : 'flex'}} className='flexRowStartCenter tab-header-bk'>


                            <button type={"button"} onClick={() => set_active_tab_curent("tab1")}
                                    className={(() => {

                                        if (String(active_tab_curent) == "tab1") {
                                            return ' btn-tab-navbk flexRowStartCenter tab-active-bk ';
                                        } else {

                                            return ' btn-tab-navbk flexRowStartCenter';
                                        }
                                    })()

                                    }>
                                <Information/><span>Information</span>
                            </button>


                            <button type={"button"} onClick={() => set_active_tab_curent("tab2")}
                                    className={(() => {

                                        if (String(active_tab_curent) == "tab2") {
                                            return ' btn-tab-navbk flexRowStartCenter tab-active-bk ';
                                        } else {

                                            return ' btn-tab-navbk flexRowStartCenter';
                                        }
                                    })()

                                    }>
                                <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g id="SVGRepo_bgCarrier" strokeWidth={0}/>
                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"/>
                                    <g id="SVGRepo_iconCarrier">{" "}
                                        <path
                                            d="M4 10.1433C4 5.64588 7.58172 2 12 2C16.4183 2 20 5.64588 20 10.1433C20 14.6055 17.4467 19.8124 13.4629 21.6744C12.5343 22.1085 11.4657 22.1085 10.5371 21.6744C6.55332 19.8124 4 14.6055 4 10.1433Z"
                                            stroke="#818288" strokeWidth="1.5"/>
                                        {" "}
                                        <circle cx={12} cy={10} r={3} stroke="#818288" strokeWidth="1.5"/>
                                        {" "}</g>
                                </svg>


                                <span>Address Information</span>
                            </button>





                        </div>


                        <div style={{
                            width: "100%",
                            "flexDirection": "column",
                            display: String(active_tab_curent) === 'tab1' ? 'flex' : 'none'
                        }}>
                            <Tab1_view setActiveTabCurrent={set_active_tab_curent} Data_Full_Get={Data_Full_Get}/>
                        </div>


                        <div style={{
                            width: "100%",
                            "flexDirection": "column",
                            "paddingTop": "28px",
                            "paddingBottom": "25px",
                            display: String(active_tab_curent) === 'tab2' ? 'flex' : 'none'
                        }}>


                            <Grid container direction="row" spacing={2} style={{paddingTop: "14px"}}>


                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <TextField
                                        style={{width: "100%"}}
                                        label="Province"
                                        select
                                        id="state"
                                        name="state"
                                        value={selectedProvince}
                                        onChange={handleProvinceChange}
                                        className={"select-option-muibk"}
                                    >
                                        {provinces.map((item, index) => (<MenuItem key={index} value={item.Province}>
                                            {item.Province}
                                        </MenuItem>))}
                                    </TextField>
                                </Grid>

                                <Grid item lg={4} md={4} sm={12} xs={12}>

                                    <TextField
                                        style={{width: "100%"}}
                                        label="City"
                                        select
                                        id="City"
                                        name={"city"}

                                        value={selectedCity}
                                        onChange={(e) => setSelectedCity(e.target.value)}
                                        disabled={!selectedProvince}
                                        className={"select-option-muibk"}

                                    >
                                        {cities.map((city, index) => (<MenuItem key={index} value={city}>
                                            {city}
                                        </MenuItem>))}
                                    </TextField>
                                </Grid>

                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <TextField
                                        style={{width: "100%"}}
                                        label="Street"
                                        placeholder='Street'
                                        id="Street"
                                        name={"street"}
                                        type={"text"}
                                        onChange={Handler_Chanche_street_input}
                                        value={street_user_input}
                                    />
                                </Grid>


                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <TextField
                                        style={{width: "100%"}}
                                        label="Postal Code"
                                        placeholder='Postal Code'
                                        id="zip"
                                        name={"zip_code"}
                                        onChange={Handler_Chanche_zip_code_input}
                                        value={zip_code_user_input}
                                    />
                                </Grid>


                            </Grid>

                            <div style={{"display": "flex", "marginTop": "16px"}}>
                                <button className={"btn-backstep"} style={{"marginRight": "9px"}} type={"button"}
                                        onClick={() => set_active_tab_curent("tab1")} >
                                    <Back/> Previous </button>
                                <button className={"btn-nextstep"}  type={"button"} onClick={Handler_saved_curent_tab2}
                                        >Submit</button>
                            </div>

                        </div>


                        <div style={{ width:"100%",display: String(active_tab_curent) === 'tab3' ? 'flex' : 'none'}}>
                            <div style={{
                                "color": "white",
                                "width": "100%",
                                "flexDirection": "column",
                                "paddingTop": "28px",
                                "paddingBottom": "25px",
                                "display": "flex",
                                "backgroundColor": "#8BC34A",
                                "borderRadius": "10px",
                                "textAlign": "center",
                                "fontSize": "17px",
                                "marginBottom": "24px",
                                "marginTop": "28px"
                            }}>
                                Profile saved successfully
                            </div>
                        </div>


                    </section>
                </section>
            </form>


        </Master_Panel>
        </Fragment>
    );
}


export default Profile_Edit;