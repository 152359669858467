import axios from "axios";
import React, {useEffect, useState, useRef, Fragment} from "react";
import {Link, NavLink, useParams, useNavigate, useLocation} from "react-router-dom"
import {render} from "react-dom";
import clientconfigwebapp, {
    empty_bk,
    convert_to_english_strhk,
    scrollTo_uphk,
    check_login_appbk,
    Token_get_appbk,
    redirectbh_typeb,
    getLengt_arraybk,
} from '../../clientconfigwebapp';


import {
    Icon_copy_clipboard,
    Logo_Icon_chat_ai
} from '../../assets/icones/icones';
import {ClassNames} from "@emotion/react";
import {ToastContainer, toast} from 'react-toastify';
const Chat_Messages_List = (props) => {


    function decodeHtmlEntities(text) {
        const textarea = document.createElement('textarea');
        textarea.innerHTML = text;
        return textarea.value;
    }

    function NewlineTextbk({text, className}) {
        // تبدیل HTML entities به کاراکترهای واقعی
        const decodedText = decodeHtmlEntities(text);

        // تبدیل \r\n یا \n به <br> و حذف هر کاراکتر اضافه ای مثل \r
        const formattedText = decodedText
            .replace(/\\r\\n|\\n/g, "<br/>")  // تبدیل \r\n و \n به <br>
            .replace(/\\r/g, "");             // حذف هر کاراکتر اضافی \r

        return (
            <div className={className} dangerouslySetInnerHTML={{__html: formattedText}}/>
        );
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                toast.success("Text successfully copied to clipboard!", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(err => {

                console.error('Failed to copy text:', err);
            });
    };


    return (<Fragment>


            {
                props.data_list && props.data_list.length > 0 && props.data_list.map((item_post, index) => {

                    return (

                        <Fragment>


                            <div key={index}
                                 className={"mb-[15px] flex flex-col content-center items-end justify-center bg-[white] px-[18px] py-[20px] rounded-[10px]"}
                                 style={{"border": "solid 3px #c3c3c34d"}}>

                                <div className={"w-full flex flex-row content-center items-center justify-start"}>
                                    <img src="/static/media/prof.3df84f32ee29c86f3cba.jpg"
                                         className={"w-[70px] h-[70px] bg-[#ffffff] rounded-[100%] p-[5px] border-[solid] border-[1px] border-[#efecec] mr-[12px]"}/>

                                    <div className={`text-ticbk`}>
                                        <NewlineTextbk text={item_post.message}
                                                       className={"text-[#818384] text-[13px] leading-[1.8] font-['Poppins']"}/>
                                    </div>

                                </div>


                                <div className="flex items-center print-actions  space-x-3">


                                    <button type={"button"} className="h-5 w-5">

                                    <span className="flex h-[30px] w-[30px] items-center justify-center">
                                    <Icon_copy_clipboard width={24} height={24} onClick={() => copyToClipboard(item_post.message)} />
                                    </span>

                                    </button>


                                </div>


                            </div>


                            <div key={index}
                                 className={"mb-[15px] flex flex-col content-center items-end justify-center bg-[#f0f8ff] px-[18px] py-[20px] rounded-[10px] ml-[3%]"}>

                                <div className={"w-full flex flex-row content-center items-center justify-start"}>

                                    <div className={"w-[70px"}>
                                        <Logo_Icon_chat_ai
                                            className={"w-[60px] h-[60px] bg-[#ffffff] rounded-[100%] p-[5px] border-[solid] border-[1px] border-[#efecec] mr-[12px]"}/>
                                    </div>
                                    <div className={`text-ticbk`}>
                                        <NewlineTextbk text={item_post.response}
                                                       className={"text-[black] text-[13px] leading-[1.8] font-['Poppins']"}/>
                                    </div>

                                </div>


                                <div className="flex items-center print-actions  space-x-3">

                                    <button className="h-5 w-5">
<span className="flex h-[30px] w-[30px] items-center justify-center">
<svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="#ababab"
    viewBox="0 0 24 24"
    className="icon-md-heavy"
>
  <path
      fill="#ababab"
      fillRule="evenodd"
      d="M12.132 2.504a1 1 0 0 1 .992-.496l.454.056a4 4 0 0 1 3.327 5.146L16.354 9h.718c2.638 0 4.553 2.508 3.86 5.053l-1.364 5A4 4 0 0 1 15.708 22H6a3 3 0 0 1-3-3v-7a3 3 0 0 1 3-3h2c.26 0 .5-.14.628-.364zM10 20h5.709a2 2 0 0 0 1.93-1.474l1.363-5A2 2 0 0 0 17.072 11H15a1 1 0 0 1-.956-1.294l.95-3.084a2 2 0 0 0-1.462-2.537l-3.168 5.543A2.72 2.72 0 0 1 9 10.81V19a1 1 0 0 0 1 1m-3-9v8c0 .35.06.687.17 1H6a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1z"
      clipRule="evenodd"
  />
</svg>

</span>

                                    </button>

                                    <button className="h-5 w-5">
<span className="flex h-[30px] w-[30px] items-center justify-center">
<svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="#ababab"
    viewBox="0 0 24 24"
    className="icon-md-heavy"
>
<path
    fill="#ababab"
    fillRule="evenodd"
    d="M11.873 21.496a1 1 0 0 1-.992.496l-.454-.056A4 4 0 0 1 7.1 16.79L7.65 15h-.718c-2.637 0-4.553-2.508-3.859-5.052l1.364-5A4 4 0 0 1 8.296 2h9.709a3 3 0 0 1 3 3v7a3 3 0 0 1-3 3h-2c-.26 0-.5.14-.628.364zM14.005 4h-5.71a2 2 0 0 0-1.929 1.474l-1.363 5A2 2 0 0 0 6.933 13h2.072a1 1 0 0 1 .955 1.294l-.949 3.084a2 2 0 0 0 1.462 2.537l3.167-5.543a2.72 2.72 0 0 1 1.364-1.182V5a1 1 0 0 0-1-1m3 9V5c0-.35-.06-.687-.171-1h1.17a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1z"
    clipRule="evenodd"
/>
</svg>
</span>

                                    </button>


                                    <button type={"button"} className="h-5 w-5">
                                    <span className="flex h-[30px] w-[30px] items-center justify-center">
                                     <Icon_copy_clipboard width={24} height={24} onClick={() => copyToClipboard(item_post.response)} />
                                    </span>
                                    </button>


                                </div>


                            </div>


                        </Fragment>

                    );
                })
            }

        </Fragment>
    );
}


export default Chat_Messages_List;