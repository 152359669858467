import React from 'react';
import BeatLoader from "react-spinners/BeatLoader";


let styles = {
    position: 'fixed',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    background: '#c0cfc0',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '2147483647',
    opacity: '1',
}

export default class FullPageLoading extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            newStyle: null
        }
    }

    componentDidMount() {
        this.setState({ newStyle: styles});
        const timer =  this.props.timer ? this.props.timer : 2000;

    }

    render () {
        const { newStyle} = this.state;
        const { loading} = this.state;


        return (
         <div>
            <div className="loading-fulpagehk"  style={this.props.loadinghk=="true" ? {'display':'block'} :{'display':'none'}}>
                <div  style={{"display":"flex","alignContent":"center","alignItems":"center","justifyContent":"center","flexWrap":"nowrap","width":"100%","height":"100%"}}>
                    <BeatLoader color="#c0cfc0" />
                </div>
              {/*  {this.props.loadinghk  }*/}
            </div>
         </div>
        )
    }
}