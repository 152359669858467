////import {show_menu_hhn} from "./App";
import {render} from "react-dom";

// const clientconfigwebapp = {
//     siteurl: "http://localhost:8005/",
//     site_url_py: "http://localhost:8080/",
//     webappurl: "http://localhost:3000/",
//     host_dl_get: "http://localhost/ai_chaptuning_hostdownload/"
// }


const clientconfigwebapp = {
    siteurl: "https://backapp.aichiptuning.com/",
    site_url_py: "https://backpyapp.aichiptuning.com/",
    webappurl: "https://aichiptuning.com/",
    host_dl_get: "https://aichiptuning.com/ai_chaptuning_hostdownload/"

}


export const is_phone_hk = new RegExp('^(\\+98|0)?([ ]|,|-|[()]){0,2}9[0|1|2|3|4|9]([ ]|,|-|[()]){0,2}(?:[0-9]([ ]|,|-|[()]){0,2}){8}');

export function seo_setmetabk(data = {}) {
    data.title = data.title || '';
    data.metaDescription = data.metaDescription || '';

    if (empty_bk(data.title) == false && String(data.title) != 'undefined') {
        document.title = String(data.title);
    }
    if (empty_bk(data.metaDescription) == false && String(data.title) != 'undefined') {
        document.querySelector('meta[name="description"]').setAttribute('content', String(data.metaDescription));
    }

}

export function fffffq(phoneget) {

    return alert(phoneget);
}

export function scrollTo_uphk(test) {
    window.scrollTo(0, 0);
}


export function scrollTo_bottom_enpage(element_id) {

    window.scrollTo({left: 0, top: document.body.scrollHeight, behavior: "smooth"});

}

export function scrollTo_element(element_id) {
    var ele = document.getElementById(element_id);
    window.scrollTo(ele.offsetLeft, ele.offsetTop);
}


export function getLengthhk(obj) {
    return (Object.values(obj)).flat().length

}

export function show_menu_hh(showorhide) {


    /// localStorage.setItem("menueopen",showorhide);

}

export function convert_to_english_strhk(strNum) {

    if (String(strNum).length > 0) {

        var pn = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
        var en = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

        var cache = strNum;
        for (var i = 0; i < 10; i++) {
            var regex_fa = new RegExp(pn[i], 'g');
            cache = cache.replace(regex_fa, en[i]);
        }
        return cache;
    }
    return strNum;

}

export function empty_bk(str) {
    try {
        if (typeof str == 'undefined' || !str || str.length === 0 || str === "" || !/[^\s]/.test(str) || /^\s*$/.test(str) || str.replace(/\s/g, "") === "")
            return true;
        else
            return false;
    } catch (e) {
        return true
    }

}


export function getLengt_bk(obj) {
    try {
        return (Object.values(obj)).flat().length
    } catch (e) {
        return 0;
    }
}


export function check_login_appbk() {
    try {
        if (!empty_bk(localStorage.getItem('tokenacess'))) {
            return true
        }
    } catch (e) {
        return false;
    }
    return false;
}

export function Token_get_appbk() {
    try {
        if (!empty_bk(localStorage.getItem('tokenacess'))) {
            return localStorage.getItem('tokenacess');
        }
    } catch (e) {
        return "";
    }
    return "";
}


export function Agent_by_user_get_appbk(get_filde = "id_agent") {
    try {
        if (!empty_bk(localStorage.getItem('id_agent_bon'))) {
            if (String(get_filde) == "id_agent") {
                return localStorage.getItem('id_agent_bon');
            }
        }
        if (!empty_bk(localStorage.getItem('agent_name_bon'))) {
            if (String(get_filde) == "agent_name") {
                return localStorage.getItem('agent_name_bon');
            }
        }
    } catch (e) {
        return "";
    }
    return "";
}

export function Agent_set_by_user(id_agent, agent_name) {
    try {
        if (!empty_bk(id_agent)) {
            localStorage.setItem('id_agent_bon', String(id_agent));
        }
        if (!empty_bk(agent_name)) {
            localStorage.setItem('agent_name_bon', String(agent_name));
        }

    } catch (e) {
        return "";
    }
    return "";
}

export function Cart_get_list_product() {
    try {

        var list_cart = [];

        list_cart = localStorage.getItem('cart');
        console.log(list_cart, "data_list_cart222222222222222222222222222222222222");
        return list_cart;


    } catch (e) {
        return [];
    }
    return [];
}

export function Cart_count_all() {
    try {

        var list_cart = [];

        list_cart = localStorage.getItem('cart');

        return list_cart.length;


    } catch (e) {
        return 1;
    }
    return 0;
}

export function get_first_last_name_local(type_get = 'full', check_engname_last = "yes") {
    try {
        var return_value = '';
        var first_name_Get = '';
        var last_name_Get = '';

        if (!empty_bk(localStorage.getItem('first_name_Get'))) {
            first_name_Get = String(localStorage.getItem('first_name_Get'));
        }

        if (!empty_bk(localStorage.getItem('last_name_Get'))) {
            last_name_Get = String(localStorage.getItem('last_name_Get'));
        }

        if (type_get == 'full') {
            return_value = String(first_name_Get) + String(last_name_Get);
        }

        if (type_get == 'first_name') {
            return_value = String(first_name_Get)
        }
        if (type_get == 'last_name') {
            return_value = String(last_name_Get)
        }


        if (String(check_engname_last) == "yes") {

            if (empty_bk(return_value) == true) {
                return 'anonymous';
            } else {
                if (search_index_ofbk(String(first_name_Get), 'engname') != -1) {
                    return 'anonymous';
                }
            }


            if (empty_bk(return_value) == true) {
                return 'Last name not specified';
            } else {
                if (search_index_ofbk(String(last_name_Get), 'engname') != -1) {
                    return 'Last name not specified';
                }
            }


        }


        return return_value;


    } catch (e) {
        return "";
    }
    return "";
}


export function FormatNumberByhkh(num, decpoint, sep, price_typebk = "") {
    try {
        // check for missing parameters and use defaults if so
        var a, x, y, z, i;
        var nStr = num + '';
        nStr = nStr.replace(/\,/g, "");
        num = nStr;

        if (arguments.length == 2) {
            sep = ",";
        }
        if (arguments.length == 1) {
            sep = ",";
            decpoint = ".";
        }
        // need a string for operations
        num = num.toString();
        // separate the whole number and the fraction if possible
        a = num.split(decpoint);
        x = a[0]; // decimal
        y = a[1]; // fraction
        z = "";


        if (typeof (x) != "undefined") {
            // reverse the digits. regexp works from left to right.
            for (i = x.length - 1; i >= 0; i--)
                z += x.charAt(i);
            // add seperators. but undo the trailing one, if there
            z = z.replace(/(\d{3})/g, "$1" + sep);
            if (z.slice(-sep.length) == sep)
                z = z.slice(0, -sep.length);
            x = "";
            // reverse again to get back the number
            for (i = z.length - 1; i >= 0; i--)
                x += z.charAt(i);
            // add the fraction back in, if it was there
            if (typeof (y) != "undefined" && y.length > 0)
                x += decpoint + y;
        }

        if (String(price_typebk == "no") || String(price_typebk) == "NO") {
            return String(x) + "";
        }
        if (String(price_typebk == "t") || String(price_typebk) == "T") {
            return String(x) + " T ";
        }
        if (String(price_typebk == "r") || String(price_typebk) == "R") {
            return String(x) + " R ";
        }


        return x;

    } catch (e) {
        return 0;
    }
}


export function rand_round_bk(min, max, interval = 1) {
    try {
        if (typeof (interval) === 'undefined') interval = 1;
        var r = Math.round(Math.random() * (max - min + interval) / interval);
        return r * interval + min;
    } catch (e) {
        return "";
    }
}

export function search_index_ofbk(cat_get_list, str_search) {
    try {
        if (!empty_bk(String(cat_get_list))) {
            return String(cat_get_list).indexOf(str_search);
        } else {
            return -1;
        }
    } catch (e) {
        return -1;
    }

    return -1;
}

export function redirectbh(url) {
    let result_reurn = "";
    result_reurn = 'window.location.href="' + url + '";';
    result_reurn = '<script>' + result_reurn + '</script>';
    result_reurn = result_reurn + '<noscript><meta http-equiv="refresh" content="0;url=' + url + '"/></noscript>';
    return result_reurn;
}

export function redirect_wait_bk(url, time = "1000") {
    let result_reurn = "";
    result_reurn = 'setTimeout(function () { window.location.href="' + url + '";},"' + time + '");';
    result_reurn = '<script>' + result_reurn + '</script>';
    result_reurn = result_reurn + '<noscript><meta http-equiv="refresh" content="0;url=' + url + '"/></noscript>';
    return result_reurn;
}

export function redirectbh_typeb(url_Get) {
    try {
        return global.window.location.href = String(url_Get);

    } catch (e) {
        return "";
    }

}

export function get_array_number_range_bk(start_number = 1, end_number = 30) {

    var get_list_all = [];
    for (let item_value = parseInt(start_number); item_value <= parseInt(end_number); item_value++) {
        get_list_all.push(item_value);
    }

    return get_list_all;

}

export function getLengt_arraybk(obj) {
    try {
        return Object.keys(obj).length;
    } catch (e) {
        return 0;
    }
}

export function check_engname_complate_profile(first_name = "", last_name = "") {
    try {

        var status_check = "yes";

        if (empty_bk(first_name) || empty_bk(last_name)) {
            status_check = "no";
        }

///alert(search_index_ofbk(first_name,'engname'));

        if ((search_index_ofbk(String(first_name), 'engname') != -1 || search_index_ofbk(String(first_name), 'englast') != -1)) {
            status_check = "no";
        }
        if ((search_index_ofbk(String(last_name), 'engname') != -1 || search_index_ofbk(String(last_name), 'englast') != -1)) {
            status_check = "no";
        }


        return status_check;
    } catch (e) {
        return "yes";
    }
    return "yes";
}

export function emptyCache() {
    if ('caches' in window) {
        caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            })
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
    }
}


export function modal_login_reg_all_hide() {

    var login_reg_view_modal = document.getElementById("login_reg_view_modal");
    if (login_reg_view_modal != null) {
        login_reg_view_modal.style.display = "none";
        login_reg_view_modal.classList.remove("active");
    }


    var login_reg_check_view_modal = document.getElementById("login_reg_check_view_modal");
    var login_view_modal = document.getElementById("login_view_modal");
    var register_view_modal = document.getElementById("register_view_modal");
    var forgot_view_modal = document.getElementById("forgot_view_modal");
    var forgot_message_view_modal = document.getElementById("forgot_message_view_modal");
    var verification_code_reg_view_modal = document.getElementById("verification_code_reg_view_modal");

    if (login_reg_check_view_modal != null) {
        login_reg_check_view_modal.style.display = "none";
        login_reg_check_view_modal.classList.remove("active");
    }
    if (login_view_modal != null) {
        login_view_modal.style.display = "none";
        login_view_modal.classList.remove("active");
    }
    if (register_view_modal != null) {
        register_view_modal.style.display = "none";
        register_view_modal.classList.remove("active");
    }
    if (forgot_view_modal != null) {
        forgot_view_modal.style.display = "none";
        forgot_view_modal.classList.remove("active");
    }
    if (forgot_message_view_modal != null) {
        forgot_message_view_modal.style.display = "none";
        forgot_message_view_modal.classList.remove("active");
    }
    if (verification_code_reg_view_modal != null) {
        verification_code_reg_view_modal.style.display = "none";
        verification_code_reg_view_modal.classList.remove("active");
    }


}

export function modal_login_reg_bk_show(modal_show_name, redirect_after_login = "") {

    modal_login_reg_all_hide();

    var login_reg_full_view_modal = document.getElementById("login_reg_view_modal");
    var login_reg_check_view_modal = document.getElementById("login_reg_check_view_modal");

    var login_view_modal = document.getElementById("login_view_modal");
    var register_view_modal = document.getElementById("register_view_modal");
    var forgot_view_modal = document.getElementById("forgot_view_modal");
    var forgot_message_view_modal = document.getElementById("forgot_message_view_modal");
    var verification_code_reg_view_modal = document.getElementById("verification_code_reg_view_modal");
    var redirect_id_loginreg = document.getElementById("redirect_id_loginreg");


    if (login_reg_full_view_modal != null) {
        login_reg_full_view_modal.style.display = "flex";
        login_reg_full_view_modal.classList.toggle('active');
    }


    if (login_reg_check_view_modal != null) {
        if (String(modal_show_name) == "login_reg_check_view_modal") {
            login_reg_check_view_modal.style.display = "block";
        }
    }
    if (login_view_modal != null) {
        if (String(modal_show_name) == "loginshow") {
            login_view_modal.style.display = "block";
        }
    }
    if (register_view_modal != null) {
        if (String(modal_show_name) == "regshow") {
            register_view_modal.style.display = "block";
        }
    }
    if (forgot_view_modal != null) {
        if (String(modal_show_name) == "forgotpassword") {
            forgot_view_modal.style.display = "block";
        }
    }
    if (forgot_message_view_modal != null) {
        if (String(modal_show_name) == "forgot_message_password") {
            forgot_message_view_modal.style.display = "block";
        }
    }
    if (verification_code_reg_view_modal != null) {
        if (String(modal_show_name) == "verification_code_reg_show") {
            verification_code_reg_view_modal.style.display = "block";
        }
    }
    if (redirect_id_loginreg != null) {

        if (!empty_bk(redirect_after_login)) {
            document.getElementById("redirect_id_loginreg").value = String(redirect_after_login);
        }


    }


}

export function modal_select_agent_show_hide(type_action = "show") {

    var modal_select_agent = document.getElementById("modal_select_agent");
    if (modal_select_agent != null) {

        modal_select_agent.classList.remove("active");

        if (String(type_action) == "show") {
            modal_select_agent.style.display = "block";
            modal_select_agent.classList.add("active");
        }
        if (String(type_action) == "hide") {
            modal_select_agent.style.display = "none";
        }

    }


}

export function modal_cart_show_hide(type_action = "show") {

    var modal_cart_mobile = document.getElementById("modal_cart_mobile");
    if (modal_cart_mobile != null) {

        if (String(type_action) == "show") {
            modal_cart_mobile.style.display = "block";
        }
        if (String(type_action) == "hide") {
            modal_cart_mobile.style.display = "none";
        }

    }

}


export function togglePopup_show_hide(id_element) {
    const popup = document.getElementById(id_element);
    if (popup) {
        if (popup.classList.contains('hidden')) {
            popup.classList.remove('hidden');
            setTimeout(() => {
                popup.classList.remove('opacity-0');
                popup.classList.add('opacity-100');
            }, 10);
        } else {
            popup.classList.remove('opacity-100');
            popup.classList.add('opacity-0');
            setTimeout(() => {
                popup.classList.add('hidden');
            }, 300);
        }
    }
}


export function modal_searchpage_hide() {

    var search_box_fixed_modal = document.getElementById("search_box_fixed_modal");
    if (search_box_fixed_modal != null) {
        search_box_fixed_modal.style.display = "none";
        search_box_fixed_modal.classList.remove("active");
    }

}

export function modal_searchpage_show() {
    var search_box_fixed_modal = document.getElementById("search_box_fixed_modal");
    if (search_box_fixed_modal != null) {
        search_box_fixed_modal.style.display = "block";
    }

}


export default clientconfigwebapp;
