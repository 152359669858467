import React, {Fragment, useEffect} from "react";


import clientconfigwebapp, {
    modal_login_reg_bk_show, scrollTo_uphk,
} from "../../clientconfigwebapp";
import Modal_Login from "../../components/Modal_Login_reg/Modal_Login";
import Modal_Register from "../../components/Modal_Login_reg/Modal_Register";
import ReactFullPageLoadinghk from "../../components/layaout/LoadingFullpagehk";
import {ToastContainer} from "react-toastify";


const Index_Login_Register = (props) => {


    useEffect(() => {


        scrollTo_uphk();
        localStorage.clear();

        modal_login_reg_bk_show("login_reg_check_view_modal");


    }, [1]);


    return (
        <Fragment>


            <div className="container mx-auto px-4">
                <ToastContainer/>
                <div className="fullbox_reg_login modal_animation_bk_2" id={"login_reg_view_modal"}
                     style={{"display": "block"}}>


                    <div className="flex justify-center w-full ">

                        <section className="w-full z-2 relative bg-white">


                            <form id="form_Registerpagewebapp">


                                <Modal_Login/>

                                <Modal_Register/>


                            </form>


                        </section>

                    </div>
                </div>


            </div>


        </Fragment>
    );
}

export default Index_Login_Register;