import React, {Fragment, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import Style_Panel_Global from "../../assets/css/Panel_styles/panel.module.css";

import Logo_site from "../../assets/images/logo.png";
import profile_image_default from "../../assets/images/prof.jpg";

import {
    Icon_Menu_Chats,
    Icon_Menu_course, Icon_Menu_Faq, Icon_menu_file, Icon_Menu_Logout,
    Icon_Menu_wallet,
    Icon_Menu_Whatsapp,
    Icon_panel_Menu_Close, Icon_Profile_hk_Chat
} from "../../assets/icones/icones";
import {togglePopup_show_hide} from "../../clientconfigwebapp";

const list_menu_user_simple = [
    {
        id: '1',
        text: 'Chats',
        icon: <Icon_Menu_Chats/>,
        href: '/',
        type_link: 'link_in',

    },
    // {
    //     text: 'Course',
    //     icon: <Icon_Menu_course />,
    //     href: '/panel/courses',
    //type_link: 'link_in',
    // },
    {
        id: '2',
        text: 'Wallet',
        icon: <Icon_Menu_wallet/>,
        href: '',
        type_link: 'popup',
        on_click_func: () => togglePopup_show_hide('wallet'),
    },
    {
        id: '31',
        text: 'File',
        icon: <Icon_menu_file/>,
        href: '/panel/files/3',
        type_link: 'link_in',
    },
    {
        id: '3',
        text: 'Support',
        icon: <Icon_Menu_Whatsapp/>,
        href: 'https://web.whatsapp.com/send?phone=+912222223333&text=',
        type_link: 'link_out',
    },

    {
        id: '4',
        text: 'faq',
        icon: <Icon_Menu_Faq/>,
        href: '/panel/faq',
        type_link: 'link_in',

    },
    {
        id: '5',
        text: 'Log out',
        icon: <Icon_Menu_Logout/>,
        href: '/login-register',
        type_link: 'link_in',
    }
];

const Menu_Sidebar = (props) => {
    const {mobileOpen, handleDrawerToggle, first_name_last_name, wallet_balance} = props;
    const location = useLocation();

    return (
        <Fragment>
            <div
                className={`h-full w-full lg:w-[120px] 
                ${mobileOpen ? 'opacity-100 visible delay-0' : 'opacity-0 delay-500 invisible'} 
                md:opacity-100 md:visible transition-opacity transition-visibility duration-500 ease-in-out 
                ${Style_Panel_Global.sidebar_menu_panel}`}
                            onClick={handleDrawerToggle}
            >
                <div className={`flex flex-col items-center py-6 ${Style_Panel_Global.sidebar_menu_panel_content}`}>
                    <Icon_panel_Menu_Close className={"block lg:hidden"}
                                           style={{position: "absolute", top: "10px", left: "2px"}} width={"30px"}
                                           height={"30px"} onClick={handleDrawerToggle}/>


                    <div className="flex flex-col items-center justify-center text-white mb-6">
                        <img src={props.profile_src} className="w-[60px] h-[60px] rounded-full " alt="Profile"/>
                        <span className={"mt-[5px]"}>{props.last_name_profile}</span>

                    </div>

                    <nav className="flex flex-col w-full">


                        {list_menu_user_simple.map((item_menu) => (


                            <Fragment>

                                {
                                    item_menu.type_link == 'link_in' && (
                                        <Link reloadDocument={true} key={item_menu.href} to={item_menu.href}
                                              className={`flex justify-center items-center p-2 mb-2 text-gray-400  hover:text-white ${location.pathname === item_menu.href ? Style_Panel_Global.link_active_menu : ''}`}>
                                            <span className={"icon-menu-left"}>{item_menu.icon}</span>
                                        </Link>
                                    )
                                }


                                {
                                    item_menu.type_link == 'link_out' && (
                                        <a key={item_menu.href} target={"_blank"} href={item_menu.href}
                                           className={`flex justify-center items-center p-2 mb-2 text-gray-400  hover:text-white ${location.pathname === item_menu.href ? Style_Panel_Global.link_active_menu : ''}`}>
                                            <span className={"icon-menu-left"}>{item_menu.icon}</span>
                                        </a>
                                    )
                                }

                                {
                                    item_menu.type_link === 'popup' && (
                                        <button type={"button"} key={item_menu.id} onClick={() => item_menu.on_click_func()}
                                                className={`flex justify-center items-center p-2 mb-2 text-gray-400 hover:text-white ${location.pathname === item_menu.href ? Style_Panel_Global.link_active_menu : ''}`}>
                                            <span className={"icon-menu-left"}>{item_menu.icon}</span>
                                        </button>
                                    )
                                }


                            </Fragment>


                        ))}
                    </nav>
                </div>
            </div>


        </Fragment>
    );
};

export default Menu_Sidebar;
