import axios from "axios";
import React, {useEffect, useState, useRef, Fragment} from "react";
import {Link, NavLink, useParams, useNavigate, useLocation} from "react-router-dom"
import {render} from "react-dom";
import clientconfigwebapp, {
    empty_bk, convert_to_english_strhk, scrollTo_uphk, check_login_appbk, Token_get_appbk, redirectbh_typeb,
} from '../../../clientconfigwebapp';
import {ToastContainer, toast} from 'react-toastify';


import {
    Iconbk_edit1
} from '../../../assets/icones/icones';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

const serialize = require('form-serialize');


///const Tab1_view = (props) => {
const Tab1_view = (props) => {

    const { Data_Full_Get, setActiveTabCurrent } = props;


    const [loadinghkfullshow, Setloadinghkfullshow] = useState("false");

    const [first_name_last_name, setData_first_name_last_name] = useState('');

    const [first_name_input, setData_first_name_input] = useState('');
    const [last_name_input, setData_last_name_input] = useState('');
    const [email_address_user, setemail_address_user] = useState('');
    const [email_mobile_number_input, setData_mobile_number_input] = useState('');
    const [gender_user_input, setgender_user_input] = useState('');
    const [date_of_birth_input, setdate_of_birth_input] = useState('');
    const [work_experience_user_input, set_work_experience_user_input] = useState('');
    const [employee_id_input, set_employee_id_user_input] = useState('');
    const [description_me_user_input, set_description_me_user_input] = useState('');


    /*  start code file_upload  */
    const [file, setFile] = React.useState();
    const [file_name_get, set_file_name] = React.useState('');
    const uploadRef = React.useRef();
    const statusRef = React.useRef();
    const loadTotalRef = React.useRef();
    const progressRef = React.useRef();
    const [attach_file_get, set_attach_file] = useState('');
    const [show_hide_pogressbar, setshow_hide_pogressbar] = useState('none');
    const [uploadUrl, setUploadUrl] = useState('')
    /*  endddd code file_upload  */



    const [rowCount, setRowCount] = useState(['row_1'])
    const [docCount, setDocCount] = useState(['doc_1', 'doc_2'])



    useEffect(() => {


        if (!empty_bk(props.Data_Full_Get.first_name_profile)) {
            setData_first_name_input(props.Data_Full_Get.first_name_profile);
        }
        
        if (!empty_bk(props.Data_Full_Get.last_name_profile)) {
            setData_last_name_input(props.Data_Full_Get.last_name_profile);
        }
        if (!empty_bk(props.Data_Full_Get.email_address_user) && String(props.Data_Full_Get.email_address_user) != "undefined") {
            setemail_address_user(props.Data_Full_Get.email_address_user);
        } else {
            setemail_address_user('');
        }

        if (!empty_bk(props.Data_Full_Get.mobile_number)) {
            setData_mobile_number_input(props.Data_Full_Get.mobile_number);
        }
        if (!empty_bk(props.Data_Full_Get.gender)) {
            setgender_user_input(props.Data_Full_Get.gender);
        }
        if (!empty_bk(props.Data_Full_Get.date_of_birth)) {
            setdate_of_birth_input(props.Data_Full_Get.date_of_birth);
        }
        if (!empty_bk(props.Data_Full_Get.work_experience)) {
            set_work_experience_user_input(props.Data_Full_Get.work_experience);
        }
        if (!empty_bk(props.Data_Full_Get.employee_id)) {
            set_employee_id_user_input(props.Data_Full_Get.employee_id);
        }


        if (!empty_bk(props.Data_Full_Get.profile_src)) {
            setUploadUrl(props.Data_Full_Get.profile_src);
        }

        if (!empty_bk(props.Data_Full_Get.description_me)) {
            set_description_me_user_input(props.Data_Full_Get.description_me);
        }


    }, [props.Data_Full_Get]);



    const emptyInputHandler = (event) => {
        const id = event.target.ariaLabel;
        if (document.querySelector(`#${id}`)) {
            document.querySelector(`#${id}`).value = ''
        }
    }
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setUploadUrl(URL.createObjectURL(file));
        document.querySelector(`#${e.target.ariaLabel}`).classList.toggle('image_up_act');

        UploadFile_start();
    }
    const deleteUploadBg = (e) => {
        if (uploadUrl !== '') {
            setUploadUrl('');
            document.querySelector(`#${e.target.ariaLabel}`).classList.toggle('image_up_act');
        }

        set_attach_file('');
    }


    const UploadFile_start = () => {
        const file = uploadRef.current.files[0];
        console.log(file, "filefilefile");
        ///// setFile(URL.createObjectURL(file));
        setFile(URL.createObjectURL(file));
        set_file_name(file.name);
        var formData = new FormData();
        formData.append("fileattach", file);
        var xhr = new XMLHttpRequest();
        xhr.upload.addEventListener("progress", ProgressHandler, false);
        xhr.addEventListener("load", SuccessHandler, false);
        xhr.addEventListener("error", ErrorHandler, false);
        xhr.addEventListener("abort", AbortHandler, false);
        xhr.open("POST", String(clientconfigwebapp.host_dl_get) + "upload.php");
        xhr.send(formData);


    };

    const ProgressHandler = (e) => {
        set_attach_file("");
        setshow_hide_pogressbar("flex");
        /// loadTotalRef.current.innerHTML = `uploaded ${e.loaded} bytes of ${e.total}`;
        var percent = (e.loaded / e.total) * 100;
        progressRef.current.value = Math.round(percent);
        ///statusRef.current.innerHTML = Math.round(percent) + "% uploaded...";
        statusRef.current.innerHTML = Math.round(percent) + "%";
    };

    const SuccessHandler = (e) => {
        set_attach_file("");
        var respons_get = e.target.response;

        ///  statusRef.current.innerHTML = e.target.responseText;
        progressRef.current.value = 0;
        statusRef.current.innerHTML = "0%";

        ///  setshow_hide_pogressbar("flex");

        console.log(respons_get, "respons_get");


        if (String(respons_get).length > 0) {

            var respons_get_json = JSON.parse(respons_get);


            if (String(respons_get_json.data).length > 0) {


                if (String(respons_get_json.data) == "success" && !empty_bk(String(respons_get_json.file_name_get))) {


                    set_attach_file(String(respons_get_json.file_name_get));
                    progressRef.current.value = 100;
                    statusRef.current.innerHTML = "Profile Picture Uploaded";

                    if (String(respons_get_json.msgwebapp).length > 0) {
                        toast.success(respons_get_json.msgwebapp, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }


                } else {


                    if (String(respons_get_json.msgwebapp).length > 0) {
                        toast.error(respons_get_json.msgwebapp, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                }


            } else {

                if (String(respons_get_json.msgwebapp).length > 0) {
                    toast.error(respons_get_json.msgwebapp, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            }


        } else {

            toast.error("Error in uploading the error code file 654754", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }


    };


    const ErrorHandler = () => {
        statusRef.current.innerHTML = "upload failed!!";
        setshow_hide_pogressbar("none");
        set_attach_file("");
        toast.error("Error in communication with the server, check your internet. Error code 345346", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const AbortHandler = () => {
        statusRef.current.innerHTML = "upload aborted!!";
        setshow_hide_pogressbar("none");
        set_attach_file("");
        toast.error("Error in communication with the server, check your internet. Error code 567567", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };


    const Handler_Chanche_description_me_input = (event) => {
        set_description_me_user_input(convert_to_english_strhk(event.target.value));
    }


    const Handler_Chanche_input_first_name = (event) => {
        setData_first_name_input(convert_to_english_strhk(event.target.value));
    }

    const Handler_Chanche_last_name_input = (event) => {
        setData_last_name_input(convert_to_english_strhk(event.target.value));
    }

    const Handler_Chanche_email_address_user = (event) => {
        setemail_address_user(String(convert_to_english_strhk(event.target.value)));
    }
    const Handler_Chanche_mobile_number_input = (event) => {
        setData_mobile_number_input(String(convert_to_english_strhk(event.target.value)));
    }


    const Handler_Chanche_gender_input = (newValue) => {
        setgender_user_input(newValue);
    }


    const Handler_Chanche_gdate_of_birth_input = (newValue) => {
        setdate_of_birth_input(newValue);
    }


    const Handler_Chanche_work_experience_input = (newValue) => {
        set_work_experience_user_input(newValue);
    }

    const Handler_Chanche_input_employee_id = (event) => {
        set_employee_id_user_input(convert_to_english_strhk(event.target.value));
    }




    const Handler_saved_curent_tab = () => {

        if (check_login_appbk() == false) {
            redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login");
        }

        const config = {
            headers: {Authorization: `Bearer ${Token_get_appbk()}`}
        };

        Setloadinghkfullshow("true");


        let form_data = document.querySelector('#form_data_profile');
        if (form_data != null) {
            let formDatanew = new FormData(form_data);
            formDatanew.append("step", "1");
            let data_serialize = new URLSearchParams(formDatanew).toString();
           /// let data_serialize = serialize(form_data);


            axios.post(`${clientconfigwebapp.siteurl}api/v1/panel/profile-edit-pr/`, data_serialize, config)
                .then((res) => {

                    Setloadinghkfullshow("false");

                    if (String(res.data).length > 0) {

                        if (String(res.data.data) == "login-show") {
                            redirectbh_typeb(String(clientconfigwebapp.webappurl) + "login");
                        }


                        if (String(res.data.status) == "success") {
                            setActiveTabCurrent('tab2');
                        } else {

                            if (String(res.data.msgwebapp).length > 0) toast.error(res.data.msgwebapp, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });

                        }


                    } else {
                        if (String(res.data.msgwebapp).length > 0) toast.error(res.data.msgwebapp, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                }).catch((err) => {
                Setloadinghkfullshow("false");
                toast.error('Error connecting to the server, code 6786324', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });


        }


    }

    return (<Fragment>
        <input type={"text"} value={attach_file_get} name="attach_file_get" style={{display: "none"}}/>
        <div className='flexColStartStart upload_image_wrapper'>

            <input
                accept="image/*"
                style={{display: 'none'}}
                id="image-upload"
                aria-label='up_1'
                type="file"
                name={"fileget"}
                ref={uploadRef} onChange={handleImageChange}

            />

            <div className='flexRowStartEnd'>
                <label className='upload_image' id='up_1' style={{backgroundImage: `url(${uploadUrl})`}}
                       htmlFor="image-upload"><span><Iconbk_edit1 /> </span></label>
            </div>


            <div style={{display: show_hide_pogressbar}}>
                <progress ref={progressRef} value="0" max="100"
                          style={{display: "none"}}/>
                <span className={"pers"} ref={statusRef} style={{
                    "marginTop": "12px",
                    "lineHeight": "1.6",
                    "color": "#009688",
                    "fontWeight": "normal",
                    "fontSize": "17px",
                    "fontFamily": "'Poppins'",
                    "display": "block",
                    "width": "100%"
                }}></span>
            </div>


        </div>


        <section className='information_content'>

            <Grid container direction="row" spacing={2} style={{paddingTop: "14px"}}>

                <Grid item lg={4} md={4} sm={12} xs={12}><TextField
                    label="First Name"
                    placeholder='First Name'
                    id="first_name"
                    name="first_name"

                    value={first_name_input}
                    onChange={Handler_Chanche_input_first_name}
                    InputProps={{
                         startAdornment: <InputAdornment position="start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                 height="24"
                                 viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M5 19.9996C5 17.4996 7 15.5996 9.4 15.5996H14.5C17 15.5996 18.9 17.5996 18.9 19.9996"
                                    stroke="#818288" stroke-width="1.4824"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"/>
                                <path
                                    d="M15.0034 5.2C16.7034 6.9 16.7034 9.6 15.0034 11.2C13.3034 12.8 10.6034 12.9 9.00341 11.2C7.40341 9.5 7.30341 6.8 9.00341 5.2C10.7034 3.6 13.3034 3.6 15.0034 5.2"
                                    stroke="#818288" stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"/>
                            </svg>
                        </InputAdornment>,
                    }}
                />
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}><TextField
                    label="Last Name"
                    placeholder='Last Name'
                    id="last_name"
                    name="last_name"
                    onChange={Handler_Chanche_last_name_input}
                    value={last_name_input}


                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                 height="24"
                                 viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M5 19.9996C5 17.4996 7 15.5996 9.4 15.5996H14.5C17 15.5996 18.9 17.5996 18.9 19.9996"
                                    stroke="#818288" stroke-width="1.4824"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"/>
                                <path
                                    d="M15.0034 5.2C16.7034 6.9 16.7034 9.6 15.0034 11.2C13.3034 12.8 10.6034 12.9 9.00341 11.2C7.40341 9.5 7.30341 6.8 9.00341 5.2C10.7034 3.6 13.3034 3.6 15.0034 5.2"
                                    stroke="#818288" stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"/>
                            </svg>
                        </InputAdornment>,
                    }}
                />
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}><TextField
                    label="Enter Your Email"
                    placeholder='Enter Your Email'
                    id="email"
                    name="email_address_user"
                    value={email_address_user}
                    onChange={Handler_Chanche_email_address_user}
                    InputProps={{
                       startAdornment: <InputAdornment position="start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                 height="24"
                                 viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M4.636 5.04492H19.363C20.267 5.04492 21 5.77792 21 6.68192V17.3179C21 18.2219 20.267 18.9539 19.364 18.9539H4.636C3.733 18.9549 3 18.2219 3 17.3179V6.68192C3 5.77792 3.733 5.04492 4.636 5.04492Z"
                                      stroke="#818288" stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path
                                    d="M3.11719 6.07455L10.8112 11.5775C11.5062 12.0745 12.4402 12.0755 13.1362 11.5795L20.8742 6.06055"
                                    stroke="#818288" stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"/>
                            </svg>
                        </InputAdornment>,
                    }}
                />
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}><TextField
                    label="Phone Number"
                    placeholder='Phone Number'
                    id="phone"
                    name="phone"
                    value={email_mobile_number_input}
                    onChange={Handler_Chanche_mobile_number_input}

                    disabled={true}

                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                 height="24"
                                 viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z"
                                    stroke="#818288" stroke-width="1.5"
                                    stroke-miterlimit="10"/>
                            </svg>
                        </InputAdornment>,
                    }}
                />

                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}><TextField
                    label="Gender"
                    select
                    id="gender"
                    name="gender"
                    value={gender_user_input}
                    onChange={(event) => Handler_Chanche_gender_input(event.target.value)}
                    className={"select-option-muibk"}
                    InputProps={{
                       startAdornment: <InputAdornment position="start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                 height="24"
                                 viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M5 19.9996C5 17.4996 7 15.5996 9.4 15.5996H14.5C17 15.5996 18.9 17.5996 18.9 19.9996"
                                    stroke="#818288" stroke-width="1.4824"
                                    stroke-linecap="round" stroke-linejoin="round"/>
                                <path
                                    d="M15.0034 5.2C16.7034 6.9 16.7034 9.6 15.0034 11.2C13.3034 12.8 10.6034 12.9 9.00341 11.2C7.40341 9.5 7.30341 6.8 9.00341 5.2C10.7034 3.6 13.3034 3.6 15.0034 5.2"
                                    stroke="#818288" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </InputAdornment>,
                    }}

                >
                    <MenuItem key={1} value={"male"}>Male</MenuItem>
                    <MenuItem key={2} value={"female"}>Female</MenuItem>
                </TextField>
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}><TextField
                    label="Date of Birth"
                    type='date'

                    id="date_of_birth"
                    name="date_of_birth"
                    value={date_of_birth_input}
                    onChange={(event) => Handler_Chanche_gdate_of_birth_input(event.target.value)}


                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                 height="24"
                                 viewBox="0 0 24 24" fill="none">
                                <path d="M16 2V6" stroke="#818288" stroke-width="1.5"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8 2V6" stroke="#818288" stroke-width="1.5"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M19 4H5C3.895 4 3 4.895 3 6V19C3 20.105 3.895 21 5 21H19C20.105 21 21 20.105 21 19V6C21 4.895 20.105 4 19 4Z"
                                      stroke="#818288" stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M3 9H21" stroke="#818288" stroke-width="1.5"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </InputAdornment>,
                    }}
                />
                </Grid>





            </Grid>


            <button type={"button"} className={"btn-nextstep"}  style={{"marginTop":"10px","marginBottom":"22px"}}  onClick={Handler_saved_curent_tab}   >Next Step</button>
        </section>

    </Fragment>);

}


export default Tab1_view;